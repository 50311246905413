import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { makeStyles, TextField } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import classes from './PasswordReset.module.css'
import CustomPrimaryButton from '../../components/CustomPrimaryButton/CustomPrimaryButton'

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginRight: '2.5px',
            marginBottom: theme.spacing(1),
            marginLeft: '2.5px'
        }
    }
}))

const REQUEST_PASSWORD_CHANGE = gql`
    mutation($email: String!) {
        requestPasswordChange(email: $email) {
            success
        }
    }
`

export const PasswordReset = () => {
    const muiClasses = useStyles()

    const [reset, setReset] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)

    const { t } = useTranslation()

    const [requestPasswordChange, {loading, error}] = useMutation(REQUEST_PASSWORD_CHANGE)

    const handleEmailChange = (event) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		setValidEmail(re.test(event.value))
        setEmail(event.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        requestPasswordChange({
            variables: {email: email}
        })
        .then(result => {
            setReset(true)
        })
        .catch(err => {})
    }

    return(
        <div className={classes.Container}>
            <form className={[classes.ResetForm, muiClasses.root].join(' ')} onSubmit={handleSubmit}>
                <div className={classes.Logo}>
                    <img src={'//d20xc7q49jirs4.cloudfront.net/static/img/login.png'} alt='bubble_logo' style={{width: '280px'}} />
                </div>

                {!reset  ?
                    <>
                        <h4 style={{fontWeight: 'bold'}}>{t('succesfulReset')}</h4>
                        <p style={{textAlign: 'justify', marginTop: '10px'}}>{t("emailedInstructions")}</p>
                        <p style={{textAlign: 'justify', marginTop: '5px', marginBottom: '15px'}}>{t("notRecieveEmailInstructions")}</p>
                        <Link to='/' className={classes.LoginLink}>{t("goToLogin")}</Link>
                    </>
                        : 
                    <>
                        <h4 style={{fontWeight: 'bold', marginTop: '10px'}}>{t("resetPassword")}</h4>
                        <p style={{textAlign: 'justify', marginTop: '5px', marginBottom: '10px'}}>{t("forgotYourPassword") + " " + t("enterEmail")}</p>
                        <TextField
                            label={t("email")}
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={Boolean(error)}
                            helperText={error ? t("emailIsNotValid") : ' '}
                        />
                        <CustomPrimaryButton className={classes.Button} type='submit' label={t("resetMyPassword")} disabled={!validEmail || loading} />
                    </>
                }
            </form>
        </div>
    ) 
}

export default PasswordReset