/* eslint-disable eqeqeq */
import React from 'react'
import { gql } from '@apollo/client'
import { saveAs } from 'file-saver'
import XLSX from 'sheetjs-style'

import { IconButton } from '@material-ui/core'
import { Description as DescriptionIcon } from '@material-ui/icons'
import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    NullClickStrategy, 
    OnlyFilterActionDisplayStrategy, 
    TableFieldDescription, 
} from '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from '../../../components/BPMTable/TableFooterItems'
import { checkProcessDuration, createStateChip } from '../../../utils/processControlUtils'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query MyExcelExports(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $state:String,
        $tableName:String,
        $createdAt:String,
    ) {
        myExcelExports(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            state:$state,
            tableName:$tableName,
            createdAt:$createdAt,
        ) {
            edges {
                node {
                    id
                    user {
                        id
                        email
                    }
                    processcontrolPtr{
                        id
                        state
                    }
                    state
                    createdAt
                    duration
                    message
                    tableCreatorId
                    tableName
            }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const dataQuery = gql`
    query excelExport (
        $id:String!
    ) {
        excelExport (id:$id) {
            id
            state
            message
            queryData
            tableColumns
            tableCreatorId
            tableName
            parentFormProps
        }
    }
`

const queries = {tableQuery};

class ExcelExportsTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ExcelExportsTableCreator"
    }

    executeDownload = (id, client) => {
        try {
            client.query({
                query: dataQuery,
                variables: { id: id},
                fetchPolicy: 'network-only',
            })
            .then(res => {
                const excelExportData = res.data

                var data = JSON.parse(excelExportData.excelExport.queryData)
                var parentFormProps = null

                if (excelExportData.excelExport.parentFormProps) {
                    parentFormProps = JSON.parse(excelExportData.excelExport.parentFormProps)
                }

                var tableCreatorColumns = JSON.parse(excelExportData.excelExport.tableColumns)

                var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(excelExportData.excelExport.tableCreatorId, null)

                if (parentFormProps) {
                    tableCreatorCreator.setParentProps(parentFormProps)
                }

                var TableObject = tableCreatorCreator.getTableCreator(excelExportData.excelExport.tableName).create(client)

                TableObject.fieldDescriptions.map(column => {
                    var field = tableCreatorColumns.filter(field => (field.id === column.id));
                    column.visibility = (field.length == 0);
                })

                const sheetName = TableObject.tableName.substring(0, 25)
                var wb = XLSX.utils.book_new()
                wb.Props = {
                    sheetName
                }
                wb.SheetNames.push(sheetName)
                var AllRowData = [] 
                const orderedHeaders = TableObject.excelOrderer(data)

                //Adds rowData
                for (let row of TableObject.excelMapper(data)) {
                    var rowData = {}
                    for (let key of Object.keys(row)) {
                        if (key !== 'id') {
                            for (let columnLabel of Object.keys(row[key])) {
                                rowData[columnLabel] = row[key][columnLabel]
                            }
                        }
                    }
                    AllRowData.push(rowData)
                }

                //gets rows max length to apply styles
                var rowsLength = []
                AllRowData.map(obj =>  rowsLength.push(Object.keys(obj).length))
                var rowMaxLength = Math.max(...rowsLength)

                //gets the row that contains the biggest amount of headers
                const headers = {}
                AllRowData.map(obj => Object.keys(obj).length === rowMaxLength ? headers.header = Object.keys(obj) : null)
                function orderDictionaryByKeys(keys, dic) {
                    const filtered = {}
                    for(let k of keys) {
                        filtered[k] = dic.hasOwnProperty([k]) ? dic[k] : ''
                    }
                    return filtered
                }
                let filteredData = []
                for(let rd of AllRowData) {
                    filteredData.push(orderDictionaryByKeys(orderedHeaders, rd))
                }            
                var ws = XLSX.utils.json_to_sheet(filteredData, {header: orderedHeaders})

                //adds styles to columns
                const generateCols = (length) => {
                    const abc = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
                    const cols = []
                    for (let i = 0; i <= length; i++) {
                        let n = Math.trunc(i / abc.length)
                        n >= 1 ? cols.push(abc[n - 1]+abc[i - n*abc.length]) : cols.push(abc[i])
                    }
                    return cols
                }
                let all_col = generateCols(rowMaxLength)
                for(let i = 0; i<rowMaxLength; i++) {
                    ws[all_col[i]+'1'].s = {	
                        font:{
                            color:{rgb: 'FFFFFF'}
                        },
                        fill: {
                                fgColor: {rgb: '29B0DA'},
                            }       
                    }
                }

                let objectMaxLength = []
                for (let i = 0; i < filteredData.length; i++) {
                    let value = Object.values(filteredData[i])
                    for (let j = 0; j < value.length; j++) {
                        if (typeof value[j] == 'number') {
                            objectMaxLength[j] = 10
                        } else if (typeof value[j] == 'object') {
                            objectMaxLength[j] = 15
                        } else {
                            const valueLength = value[j] ? value[j].length : 0
                            objectMaxLength[j] =
                                objectMaxLength[j] >= valueLength
                                    ? objectMaxLength[j]
                                    : valueLength
                        }
                    }
                    let key = Object.keys(filteredData[i])
                    for (let j = 0; j < key.length; j++) {
                        objectMaxLength[j] =
                            objectMaxLength[j] >= key[j].length
                                ? objectMaxLength[j]
                                : key[j].length
                    }
                }
                var wscols = objectMaxLength.map(w => { return({width: w}) })
            
                ws['!cols'] = wscols

                wb.Sheets[sheetName] = ws
                function s2ab(s) { 
                    var buf = new ArrayBuffer(s.length)
                    var view = new Uint8Array(buf)
                    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF
                    return buf   
                }

                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx', 
                    type: 'binary', 
                    cellDates: true, 
                    cellNF: false, 
                    raw: true, 
                    cellText: false
                })

                saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), sheetName+".xlsx")
            })
        } catch (error) {
            console.log(error)
        }
    }

    getTableCreator = (tableName) => {
        const downloadExcel = data => {
            return (
                data?.state.toUpperCase() === 'FINISHED' ?
                    <IconButton onClick={ event => { this.executeDownload(data.id, this.client) }}>
                        <DescriptionIcon />
                    </IconButton>
                : <></>
            )
        }

        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, queries, 
                                                    new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setAdditionalFilters({first: 30})
        tableCreator.setInitialSortField('createdAt', 'desc') 
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'user',
                new DataMapper("user.email"),
                new f.FilterDescription(new f.TextFilter("user")),
                {label: 'User', onlyForm: true}
            ),
            new TableFieldDescription(
                'tableName',
                new DataMapper("tableName"),
                new f.FilterDescription(new f.TextFilter('tableName')),
                {label: 'Table Name'}
            ),
            new TableFieldDescription(
                'createdAt',
                new DataMapper("createdAt"),
                new f.FilterDescription(new f.DateFilter("createdAt")),
                {label: 'Creation Date', transform: new DataTransformer(({createdAt}) => createdAt.split('.')[0].replace('T',' '))}
            ),
            new TableFieldDescription(
                'duration',
                new DataMapper("duration", "createdAt", "state"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Duration', transform: new DataTransformer(({duration, createdAt, state}) => checkProcessDuration(duration, createdAt, state))}
            ),
            new TableFieldDescription(
                'state',
                new DataMapper("state"),
                new f.FilterDescription(new f.ProcessControlStateFilter("state")),
                {label: 'Status', transform: new DataTransformer(({state}) => createStateChip(state))}
            ),
            new TableFieldDescription(
                'message',
                new DataMapper("message"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Message', onlyForm: true}
            ),
            new TableFieldDescription(
                'downloadFile',
                new DataMapper("processcontrolPtr"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Download File', hideSort: true, transform: new DataTransformer(({processcontrolPtr}) => downloadExcel(processcontrolPtr))}
            ),
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ExcelExportsTableCreator)

export default ExcelExportsTableCreator;