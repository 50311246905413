import React from 'react'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';

export const getBrowserLanguage = () => {
    const userLanguage = navigator.language || navigator.userLanguage
    return userLanguage.split('-')[0];
};

export const passwordValidationMessages = (t) => {
    return {
        passwordRequirements: t('passwordRequirements'),
        strLowercase: t('mustHaveLowerCase'),
        strUppercase: t('mustHaveUpperCase'),
        strDigit: t('mustHaveDigit'),
        strSpecialCharacter: t('mustHaveSpecialCharacter'),
        strMinLength: t('passworMinLength'),
        strPasswordMatch: t('passwordMustMatch'),
        errorPasswordValidation: t('errorPasswordValidation'),
    }
}

export const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()<>\-_=+|{}:"\\,./])[A-Za-z\d!@#$%^&*()<>\-_=+|{}:"\\,./]{8,}$/;
    return passwordRegex.test(password);
}

export const getNewPasswordValidationMessage = (password, validationMessages) => {
    const reqs = [
        { label: validationMessages.strMinLength, isValid: password.newPassword.length >= 8 },
        { label: validationMessages.strUppercase, isValid: /[A-Z]/.test(password.newPassword) },
        { label: validationMessages.strLowercase, isValid: /[a-z]/.test(password.newPassword) },
        { label: validationMessages.strDigit, isValid: /\d/.test(password.newPassword) },
        { label: validationMessages.strSpecialCharacter, isValid: /[!@#$%^&*()<>\-_=+|{}:"\\,./]/.test(password.newPassword) },
        { label: validationMessages.strPasswordMatch, isValid: (password.repeatPassword.length > 0 && password.repeatPassword === password.newPassword) }
    ]

    return (
        <>
            <p style={{ fontWeight: "bold" }}>{validationMessages.passwordRequirements + ":"}</p>
            <span style={{ whiteSpace: 'pre-line' }}>
                {reqs.map((req, index) => (
                    <div key={index} style={{ color: req.isValid ? 'green' : 'red' }}>
                        {req.isValid ?
                            <CheckCircleOutlineIcon style={{ color: 'green' }} />
                        :
                            <CancelOutlinedIcon style={{ color: 'red' }} />
                        }
                        {' ' + req.label + '.'}
                    </div>
                ))}
            </span>
        </>
    )
}
