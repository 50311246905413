import React from 'react'

import { useTranslation } from 'react-i18next'

const PopupContent = (props) => {
    const { t } = useTranslation(['tableNames', 'popupDetails', 'fieldLabels'])
    const {data, TableObject} = props
    delete data['rawData']

    return (
        <div>
            <h4 style={{margin: 20, color: '#555555'}}>{`${t(TableObject.tableName)} ${t('popupDetails:Details')}`}</h4>
            <hr style={{border: 0, clear: 'both', display: 'block', width: '96%', color: 'black', backgroundColor: 'black', height: 3, opacity: 0.5, margin: '10px auto'}} />
            <table 
                align='center' 
                style={{
                    tableLayout: 'fixed', 
                    paddingRight: 10, 
                    marginLeft: 30, 
                    marginRight: 30,
                    color: '#555555'}}>
                <tbody>
                    {Object.keys(data).map((id, index) => {
                        return (
                            <tr key={`row_${index}`} style={{maxHeight:'300px'}}>
                                <th key={`col1_${index}`} style={{verticalAlign:'top'}}>
                                    {`${t(`fieldLabels:${TableObject.getFieldLabelById(id)}`)}:`}
                                </th>
                                <td key={`col2_${index}`} style={{paddingLeft: 10, maxHeight:'300px', overflow:'auto'}}>
                                    <div style={{maxHeight:'200px', overflow:'auto'}}>
                                        {(data[id].props.value === null || data[id].props.value === undefined) ? '' : String(data[id].props.value).split("\n").map((text, i) => i ? [<br/>, text] : text)}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <br />
            <br />
        </div>
    )
}

export default PopupContent