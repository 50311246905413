import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next'

import { FormControl, Button, InputLabel, Select, MenuItem, Card, Typography } from '@material-ui/core'
import { Dialog, Input, Box, Chip, Paper } from '@material-ui/core'
import {TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'

import Loading from '../../../Loading/Loading'


const ITEM_HEIGHT = 35;
const ITEM_PADDING_TOP = 8;

const menuprops = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        },
    },
};

function CustomTable(props) {
  const {
    items,
    tableMultiPickerColumnTransformers,
    onToggle,
    side,
  } = props;

  const headerStyle = {
    backgroundColor:"#0A1F2E",
    height: 10,
  }

  const rowStyle = {
    height: 10,
  }

  return (
    <Paper style={{overflow:"auto", maxHeight:300}}>
      <TableContainer component={Paper}>
        {/*<Table stickyHeader aria-label="sticky customized table">*/}
        <Table aria-label="customized table">
          <TableHead style={headerStyle}>
            <TableRow style={rowStyle}>
              {tableMultiPickerColumnTransformers.map((transformer, index) => <TableCell key={index} align="center" style={{color:"white", height:"auto !important"}}>{transformer.label}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? 
              <>
                {
                  items.map((item, index) => (
                    <TableRow key={index} hover style={rowStyle} onClick={() => onToggle(item.id, side)}>
                      {tableMultiPickerColumnTransformers.map((transformer, idx) => {
                        return (
                          <TableCell key={"r_"+index+"_c_"+idx} align="center" style={{height:"auto !important"}}>
                            {String(transformer.mapper(item.item))}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                }
              </>
            :
              <TableRow style={rowStyle}>
                <TableCell align="left" style={{height:"auto !important"}}>No Data</TableCell>
              </TableRow>
            }
            <TableRow/>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function TableMultiPickerSelector(props) {
  const {
    items,
    assigned,
    onSave,
    tableMultiPickerColumnTransformers,
    inputLabel,
  } = props;

  const allItemIds = items.map(item => item.id);
  const initialAvailable = allItemIds.filter(id => assigned.indexOf(id) < 0);

  const [filterSelecteds, setFilterSelecteds] = useState("");
  const [filterAvailables, setFilterAvailables] = useState("");
  const [currentAvailable, setCurrentAvailable] = useState([...initialAvailable]);
  const [currentSelected, setCurrentSelected] = useState([...assigned]);
 
  const onClear = () => {
    setCurrentSelected([]);
    setCurrentAvailable(allItemIds);
    
  }

  const handleSave = () => {
    const selectedItems = items.filter(item => currentSelected.indexOf(item.id) >= 0);
    onSave(selectedItems);
  }

  const handleToggle = (id, side) => {
    var srcItems = null
    var dstItems = null
    if (side === "SELECTED") {
      srcItems = [...currentSelected];
      dstItems = [...currentAvailable];
    } else {
      srcItems = [...currentAvailable];
      dstItems = [...currentSelected];
    }

    const index = srcItems.indexOf(id);
    if (index > -1) {
      dstItems.push(id);
      srcItems.splice(index, 1);

      if (side === "SELECTED") {
        setCurrentAvailable(dstItems);
        setCurrentSelected(srcItems);
      } else {
        setCurrentAvailable(srcItems);
        setCurrentSelected(dstItems);
      }
    }
  }

  const buildFilterRegexp = (filter) => {
    return RegExp(filter, "i");
  }
  
  const filterWith = (item, filter) => {
    return (
      !filter ||
      item.labels[0].match(buildFilterRegexp(filter)) || 
      item.labels[1].match(buildFilterRegexp(filter))
    )
  }

  return (
    <div style={{width:900}}>
      <Typography variant="h6" style={{textAlign:"center",margin:"3%"}}>{inputLabel}</Typography>     
      <Box>
        <Box style={{margin:"3.0%"}}>
          <Card style={{ margin:"3.0%", border: "none", boxShadow: "none" }}> 
            <TextField style={{marginLeft:"7.5%", width:300}} label="Filter" onChange={e => setFilterAvailables(e.currentTarget.value)}/>
          </Card>
          <Card>
            <CustomTable tableMultiPickerColumnTransformers={tableMultiPickerColumnTransformers} items={items.filter(item=>currentAvailable.indexOf(item.id) >= 0).filter(item => filterWith(item, filterAvailables))} onToggle={handleToggle} side="UNSELECTED"/>
          </Card>
        </Box>
        <Box>
          <Box style={{margin:"3.0%"}}>
            <Card style={{ margin:"3.0%", border: "none", boxShadow: "none" }}> 
              <Typography align="center" style={{ textAlign: "center", verticalAlign: "middle", fontWeight: "bold"}}>Selected data</Typography>
            </Card>
            <Card> 
              <CustomTable tableMultiPickerColumnTransformers={tableMultiPickerColumnTransformers} items={items.filter(item => currentSelected.indexOf(item.id) >= 0).filter(item => filterWith(item, filterSelecteds))} onToggle={handleToggle} side="SELECTED"/>
            </Card>
          </Box>
          <Box style={{margin:"3.0%"}}>
            <Card style={{ border: "none", boxShadow: "none" }}>
              <Button onClick={handleSave} style={{ float:"left", display: "flex", justifyContent: "center", alignItems: "center", margin: "4.0" }} color="primary" variant="contained">Save</Button>        
              <Button onClick={onClear} style={{ float:"right", display: "flex", justifyContent: "center", alignItems: "center", margin: "4.0" }}>Clear<DeleteIcon/></Button> 
            </Card>
          </Box>
        </Box>
      </Box>
    </div>
  )  
}

function createDataItem(item, labelTransformers) {
  return {
    id:item.id,
    item: item,
    labels: labelTransformers.map(transformer => String(transformer.mapper(item))),
  }
}

function TableMultiPicker(props) {
  const {
    classes,
    label,
    id,
    t,
    required,
    query,
    formState,
    queryName,
    tableMultiPickerColumnTransformers,
    inputId,
  } = props;

  const [tableOpen, setTableOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(formState.displayData[inputId] ? formState.displayData[inputId].map(item=>(createDataItem(item, tableMultiPickerColumnTransformers))) : []);

  const initialRender = useRef(true);

  useEffect(()=> {
    if (initialRender.current) {
      initialRender.current=false;
    } else {
      setSelectedData([]);
    }
  }, props.watchedStateIdentifierForSelectionClear ? [formState.mappedData[props.watchedStateIdentifierForSelectionClear]] : null);

  const getQueryVariables = () => {
    return props.queryVariablesTransformer ? props.queryVariablesTransformer(formState.mappedData) : null;
  }

  const validateQueryVariablesTransformer = () => {
    const variable = props.queryVariablesTransformer(formState.mappedData);
    const clavesVariable = Object.keys(variable);

    if (clavesVariable.length > 0) {
      const claveVarible = clavesVariable[0];
      return variable[claveVarible];
    }

    return null;
  }

  const handleOpenTable = () => {
      setTableOpen(true);
  }

  const handleCloseTable = () => {
      setTableOpen(false);
  }

  const clearOption = (inputId) => {
      props.onChangeHandler(props.inputId, null, '');
  }

  const handleSave = (selectedItems) => {
    const ids = selectedItems.map(item=> parseInt(item.id));
    const labels = selectedItems.map(item=>item.item);
    props.onChangeHandler(props.inputId, Object(ids), labels);
    setSelectedData(selectedItems);
    setTableOpen(false);
  }

  const inputLabel = required ? required(t(label)) : t(label);

  return (
    <div>
      <div>
        <FormControl style={{width:250}} className={classes.formControl} >
          <InputLabel htmlFor={label}>{inputLabel}</InputLabel>
          <Select disabled={!validateQueryVariablesTransformer()}
            value={" "}
            IconComponent={SearchIcon}
            onOpen={handleOpenTable}
            open={false}
            menuprops={menuprops}
            style={{width:'100%'}}
            className={classes.input}
            id={id}
            input={<Input />}
          >
            <MenuItem key={''} value={null} className={classes.input}> - </MenuItem>
          </Select>
        </FormControl>
        <Paper elevation={1} style={{width:250,margin:"-8px 0px 8px 8px"}}>
          <div style={{padding:10, overflow:'auto', maxHeight:250}}>
            {selectedData.length > 0 ? 
              <>
                {
                  selectedData.map(item =>
                    <Chip
                      style={{margin:2}}
                      color={'primary'}
                      size='small'
                      variant='outlined'
                      key={item.id}
                      label={item.labels[0]}
                    />
                  )
                }
              </>
            :
              <Chip style={{margin:2}}
              color={'primary'}
              size='medium'
              variant='outlined'
              label={"Unassigned"}
              />
            }
          </div>
        </Paper>
      </div>
      <Dialog 
        open={tableOpen} 
        maxWidth='lg'
        scroll="paper"
        onClose={handleCloseTable} 
        id={'pickerDialog'+ label }
      >
        <span onClick={()=>{setTableOpen(false)}} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '10px', top: '5px'}}>&times;</span>
        <Loading query={query} variables={getQueryVariables()}>
          {(data)=> { 
            const items = data[queryName].edges.map(edge => createDataItem(edge.node, tableMultiPickerColumnTransformers))

            return(
              <TableMultiPickerSelector items={items} assigned={selectedData.map(item => String(item.id))} onSave={handleSave} inputLabel={inputLabel} tableMultiPickerColumnTransformers={tableMultiPickerColumnTransformers} />
            )
          }}
        </Loading>
      </Dialog>
    </div>
  )
}

export default withTranslation("TableMultiPicker")(TableMultiPicker)

