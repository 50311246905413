import React from 'react'
import { useTranslation } from 'react-i18next';


export const TextTranslator = (props) => {
    const {t} = useTranslation();
    const text = props.ns ? props.ns + ":" + props.text : props.text

    return t(text)
}

export const FieldLabelsTranslator = (props) => {
    const {t} = useTranslation();
    return t("fieldLabels:" + props.text)
}

export const getTranslationEntry = (value, namespace, t) => {
    const value_ns = namespace ? namespace.concat(':').concat(value) : value;
    return t(value_ns)
}

export const getStateTranslationEntry = (value, t) => {
    const value_ns = 'stateFilter'.concat(':').concat(value);
    return t(value_ns)
}
