/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable import/first */
/* eslint-disable no-unused-expressions */
import React, {useState} from "react"

import _, { values } from 'lodash';

import { useDispatch } from "react-redux";

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ApolloConsumer, gql, useApolloClient } from '@apollo/client';
import { Chip, Avatar, makeStyles } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';;
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

import VisibilityIcon from '@material-ui/icons//Visibility';
import VisibilityOffIcon from '@material-ui/icons//VisibilityOff';

import TableManager from "./TableManager";
import FormManager from "./FormManager";
import PopupContent from "./TableComponents/Popups/PopupContent";
import { inactiveSVG , activeSVG, nullSVG } from "./TableComponents/Icons/StatusSVGs"
import { thousandSeparator } from '../../utils/tools'
import { ButtonTooltip } from "../Tooltips/BPMTooltips";
import PopUpData from "./TableComponents/Popups/Popup"
import InvoicePaymentGroupPopUp from "../../pages/Invoice/InvoicePayment/InvoiceGroupPopUp"
import { IconButton, Grid, Tooltip} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'
import {ExportFooterItemCreator, MassiveUploadFooterItemCreator} from "./TableFooterItems"
import { setFirstPublishedPeriod, setLastPublishedPeriod } from "../../redux/reducers/BPMReducer";

class FormCreator{

    constructor(formCreatorId, name, queries, onConfirmFormStrategy, onCancelFormStrategy){
        this.formCreatorId = formCreatorId;
        this.formName = name;
        this.unitQuery = queries.unitQuery;
        this.unitQueryName = this.getQueryName(queries.unitQuery)
        this.newMutator = queries.newMutator;
        this.editMutator = queries.editMutator;
        this.onConfirmFormStrategy  = onConfirmFormStrategy || new DefaultFormConfirmStrategy();
        this.onCancelFormStrategy  = onCancelFormStrategy || new DefaultFormCancelStrategy();
        this._inputs = [];
    }

    setOnConfirmFormStrategy  = strat => {
        this.onConfirmFormStrategy = strat
    }
    setOnCancelFormStrategy  = strat => {
        this.onCancelFormStrategy = strat
    }


    addInputs(inputs){
        this._inputs = this._inputs.concat(inputs);
    }

    getQueryName(query){
        return query ? query.split('{')[0].split('(')[0].trim() : false;
    }

    set inputs(inputs){
        this._inputs = inputs;
    }

    create = () =>{
        return new FormManager(
            this.formCreatorId,
            this.formName,
            this.unitQuery,
            this.unitQueryName,
            this.newMutator,
            this.editMutator,
            this._inputs,
            this.onConfirmFormStrategy,
            this.onCancelFormStrategy,
        )
    }
}

class DefaultTranslationsStrategy{
    translate = (label,t) => {
        return (t('fieldLabels:'+label))
    }
}

function EditButtonComponent(props){
    return(
        <>
            { props.editButtonDisplay ?
                <Link to={`edit/${props.data.id}`}>
                    <IconButton size="medium" style={{padding: 0}}>
                        <EditIcon />
                    </IconButton>
                </Link>
            :
                null
            }
        </>
    )
}


class DefaultEditButtonStrategy{
    createWith = (editButtonDisplay, data) =>{
        return <EditButtonComponent editButtonDisplay={editButtonDisplay} data={data} />
    }
}

class TableCreator{
    constructor(tableCreatorId, tableName, queries, onClickStrategy, displayStrategy, popupContentStrategy){
        this.tableCreatorId = tableCreatorId;
        this.tableName = tableName;
        this.tableQuery = queries.tableQuery;
        this.unitQuery = queries.unitQuery;
        this.deleteMutator = queries.deleteMutator;
        this.onClickStrategy = onClickStrategy  || new DefaultClickStrategy();
        this.displayStrategy = displayStrategy || new DefaultDisplayStrategy();
        this.popupContentStrategy = popupContentStrategy || new DefaultPopupContentDisplayStrategy();
        this.selectedRowActionsStrategy = new DefaultSelectedRowsActionsStrategy();
        this.tableTranslation = new DefaultTranslationsStrategy();
        this.massiveUploadName = tableName;
        this.massiveUploadTemplateUrlAppend = undefined;
        this._descriptions = [];
        this.tableMapper = new DefaultTableNodeDataGetter();
        this.additionalFilters = {};
        this.initialFilters = {};
        this.rightButtonDisplay = new DefaultEditButtonStrategy();
        this.tableObjectMapper = new DefaultObjectNodeGetter();
        this.tableFooterItems = [new ExportFooterItemCreator(), new MassiveUploadFooterItemCreator()];
        this.tableCreatorCreatorParentProps = null;
    }

    setTableCreatorCreatorParentProps = tableCreatorCreatorParentProps => {
        this.tableCreatorCreatorParentProps = tableCreatorCreatorParentProps
    }

    setCustomTableMapper = mapper => {
        this.tableMapper = mapper
    }

    setTableQuery = query => {
        this.tableQuery = query
    }

    setMassiveUploadTemplateUrlAppend = append => {
        this.massiveUploadTemplateUrlAppend  = append;
    }

    setSelectedRowActionsStrategy = strategy => {
        this.selectedRowActionsStrategy = strategy;
    }

    addDescriptions(description){
        this._descriptions = this._descriptions.concat(description);
    }

    setInitialSortField = (fieldName, direction) => {
        this.initialSortFieldName = fieldName;
        this.initialSortFieldDirection = direction;
    }

    setDisplayStrategy = strategy => {
        this.displayStrategy = strategy;
    }
    setPopupContentStrategy = (strategy) => {
        this.popupContentStrategy = strategy;
    }
    setOnClickStrategy = (strategy) => {
        this.onClickStrategy = strategy;
    }

    setDescriptions(descriptions){
        this._descriptions = descriptions;
    }

    setAdditionalFilters(filters) {
        this.additionalFilters = {...filters};
    }

    setInitialFilters(filters) {
        this.initialFilters = {...filters};
    }

    setRightButtonDisplay = strategy => {
        this.rightButtonDisplay = strategy;
    }

    setTableFooterItems = tableFooterItemObjects => {
        this.tableFooterItems = tableFooterItemObjects
    }

    addTableFooterItems = tableFooterItemObjects => {
        this.tableFooterItems = [...this.tableFooterItems, ...tableFooterItemObjects]
    }

    create = (client, dispatch, reducers, stateSelectors) =>{
        return new TableManager(
            this.tableCreatorId,
            this.tableName,
            this.tableQuery,
            this.deleteMutator,
            this.unitQuery,
            this._descriptions,
            this.onClickStrategy,
            this.displayStrategy,
            this.selectedRowActionsStrategy,
            this.popupContentStrategy,
            this.massiveUploadName,
            this.massiveUploadTemplateUrlAppend,
            this.tableMapper,
            this.additionalFilters,
            this.initialFilters,
            this.tableTranslation,
            this.initialSortFieldName,
            this.initialSortFieldDirection,
            client,
            this.rightButtonDisplay,
            this.tableObjectMapper,
            this.tableFooterItems,
            this.tableCreatorCreatorParentProps,
            dispatch,
            reducers,
            stateSelectors
        )
    }

}

class FormSection {
    constructor(name, priority) {
        this.name = name;
        this.priority = priority;
    }
}

class TableColumSection {
    constructor(id, label, translateLabel) {
        this.id = id;
        this.label = label;
        this.translateLabel = translateLabel;
    }
}

class DefaultExcelDisplayCreator{
    getExcelDisplay(header, data){
        const value = i18next.t("fieldLabels:" + header)
        return {[value]: data}
    }

    getOrder(){
        return 0
    }
}


class ManagerExcelDisplayCreator extends DefaultExcelDisplayCreator{

    getExcelDisplay(header, data) {
        let display = {}
        let n = 1
        for(let manager of data) {
            const managerTr = i18next.t("fieldLabels:Manager")
            const codeTr = i18next.t("fieldLabels:Code")
            const nameTr = i18next.t("fieldLabels:Full Name")

            display[`${managerTr} ${n} ${codeTr}`] = manager.enterpriseId
            display[`${managerTr} ${n} ${nameTr}`] = `${manager.firstName} ${manager.lastName}`

            n = n + 1
        }
        return display
    }
}


class BooleanExcelDisplayCreator extends DefaultExcelDisplayCreator{
    constructor(labelTrue="Active", labelFalse="Inactive") {
        super();
        this.labelTrue=labelTrue;
        this.labelFalse=labelFalse;
    }

    getExcelDisplay(header, data) {
        const labelTrueTr = i18next.t("stateFilter:"+this.labelTrue);
        const labelFalseTr = i18next.t("stateFilter:"+this.labelFalse);

        return {[header]: String(data) == 'true' ? labelTrueTr : labelFalseTr};
    }
}


class GeneralExcelDisplayCreator extends DefaultExcelDisplayCreator{
    constructor(label, fields, order=1){
        super()
        this.label = label;
        this.fields = fields;
        this.order = order;
    }

    getExcelDisplay(header, data) {
        let display = {}
        let n = 1
        const labelTr = i18next.t("stateFilter:"+this.label);
        for(let item of data) {
            if(item){
                for (let field of this.fields) {
                    const fieldLabelTr = i18next.t("stateFilter:"+field.label);
                    display[`${labelTr} ${n} ${fieldLabelTr}`] = _.get(item, field.path, field.defaultValue || '')
                }
            }
            n = n + 1
        }
        return display
    }

    getOrder(){
        return this.order
    }
}


class TableFieldDescription {
    constructor(id, tableMapper, inputDescription, optionals={}){
        this.id = id ;
        this.tableMapper = tableMapper;
        this.popupMapper = optionals['popupMapper'] || this.tableMapper;
        this.inputDescription = inputDescription;
        this.label = optionals['label'];
        this.onlyForm = optionals['onlyForm'] || false;
        this.formatDisplay = optionals['formatDisplay '] || this.defaultNullFunction ;
        this.getChildren = optionals['getChildren'] || this.defaultNullFunction ;
        this.queryName = this.getQueryName(this.queryAllDropdownOptions);
        this.transformer = optionals['transform'] || new DataTransformer();
        this.excelTransformer = optionals['excelTransform'] || this.transformer;
        this.excelDisplay = optionals['excelDisplay'] || new DefaultExcelDisplayCreator();
        this.componentCreator = optionals['componentCreator'] || new DefaultComponentCreator();
        this.orderIdentifier = optionals['orderIdentifier'] || this.id;
        this.hideSort = optionals['hideSort'] || false;
        this.draggable = Boolean(optionals['draggable']);
        this.section = optionals['section'] || null;
        this.translateLabel = optionals['translateLabel']===undefined || optionals['translateLabel'];
        this.hidenField = optionals['hidenField'] || false;
    }

    getVisibility = (fieldsConfig) => {
        const field = fieldsConfig ? fieldsConfig.find(c => c.id === this.id) : null
        return this.getFieldVisibility(field)
    }

    getFieldVisibility = (field) => {
        return !this.hidenField && (field ? !field.visibility : !this.onlyForm)
    }

    getQueryName(query){
        return query ? query.split('{')[0].split('(')[0].trim() : false;
    }

    set visibility(visibility){
        this.onlyForm = visibility;
    }

    mapTableData = (data) => {
        return this.tableMapper.mapWith(data);
    }

    defaultNullFunction = (data) => data;

    transform = (data) => {
        return this.transformer.transform(data)
    }

    excelTransform = (data) => {
        return {
            rowData: this.excelDisplay.getExcelDisplay(this.label, this.excelTransformer.excelDataProcessor(data)), 
            order: this.excelDisplay.getOrder()
        }
    }

    getLabel = (tableTranslation, t) => {
        if (this.translateLabel && this.label) {
            return tableTranslation.translate(this.label, t)
        }

        return this.label;
    }
}

function defaultPrepareData(data) {
    if (
        Array.isArray(data) ||
        typeof(data) == 'boolean' ||
        typeof(data) == 'string' ||
        data === null ){
        return data;
    }else if(typeof(data) == "object" && data.id){
        return data.id;
    }else if(typeof(data) == "object"){
        return data;
    }
}



class FormFieldDescription{
    constructor(id,inputDescription, section, priorityOnSection, optionals={}){
        this.id = id;
        this.inputDescription = inputDescription;
        this.label = optionals['label'] ;
        this.formatDisplay = optionals['formatDisplay '] || this.defaultNullFunction ;
        this.getChildren = optionals['getChildren'] || this.defaultNullFunction ;
        this.required = optionals['required'] || false;
        this.readOnly = optionals['readOnly'] || false;
        this.queryAllDropdownOptions = optionals['queryAllDropdownOptions'] || false;
        this.dropdownCustomOptions = optionals['dropdownCustomOptions'] || false;
        this.queryName = this.getQueryName(this.queryAllDropdownOptions);
        this.transformer = optionals['transform'] || new DataTransformer();
        this.excelTransformer = optionals['excelTransform'] || this.transformer;
        this.section = section;
        this.priorityOnSection = priorityOnSection;
        this.dataMapper = optionals['dataMapper'] || (data => data[this.id]);
        this.prepareData = optionals['prepareData'] || defaultPrepareData;
    }

    getQueryName(query){
        return query ? query.split('{')[0].split('(')[0].trim() : false;
    }

    defaultNullFunction = (data) => data;

    transform = (data) => {
        return this.transformer.transform(data)
    }

    mapData = (data) => {
        const value = this.dataMapper(data);
        return value !== undefined && value !== null ? value : this.inputDescription.getDefaultValue();
    }

    prepareData = data => {
        return this.prepareData(data)
    }

    stateTransformer = data => {
        if (data == null){
            return null
        }
        return this.transformer.stateTransformer(data)
    }

    excelTransform = (data) => {
        return this.excelTransformer.excelDataProcessor(data)
    }
}

class DataMapper{
    constructor(path){
        const args = Array.prototype.slice.call(arguments);
        this.mapperPaths = args.map(path => path.split("."));
    }
    mapWith = (data) =>{
        const res = {};
        this.mapperPaths.map(path =>{
            let _data = { ...data };
            path.map(key => _data = _data?.[key] ?? "" );
            res[path[path.length - 1]] = _data
        })
        return res;
    }
}

class DefaultComponent extends React.Component {
    render() {
        return (
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                <span style={{whiteSpace: 'nowrap'}}>
                    {this.props.value}
                </span>
            </div>
        )
    }
}

class DefaultComponentCreator {
    constructor(mapper){
        this.mapper = mapper || null
    }
    createWith(data) {
        return (
            <DefaultComponent value={this.mapper ? this.mapper(data) : data}/>
        )
    }
}

class NumberComponent extends React.Component {
    render() {
        const value = Number(this.props.value);
        const precision = this.props.precision ? this.props.precision : 0;
        const sufix = this.props.sufix ? this.props.sufix : '';
        return (
            <div style={this.props.style ? this.props.style: {textAlign:"right",paddingRight:"33%"}}>
              <span>
                  {this.props.currencySymbol + thousandSeparator(value, precision) + sufix}
              </span>
            </div>
        )
    }
}

class NumberComponentCreator extends DefaultComponentCreator {
    constructor(currencySymbol, precision, sufix) {
        super()
        this.currencySymbol = currencySymbol || "";
        this.sufix = sufix || "";
        this.precision = precision || 0;
    }

    createWith(data) {
        return (
            <NumberComponent currencySymbol={this.currencySymbol} sufix={this.sufix} precision={this.precision} value={data}/>
        )
    }

}

class NumberIconedComponent extends React.Component {
    render() {
        const value = Number(this.props.value);
        const precision = this.props.precision ? this.props.precision : 0;
        return (
            <div style={{display:'flex'}}>
                {this.props.currencySymbol + thousandSeparator(value, precision)}
                {(this.props.buttonType?.type === "warnUnValidatedsIcon") ?
                    <ButtonTooltip title={this.props.buttonType?.caption}>
                        <InfoTwoToneIcon style={{ color: '#E67E22', fontSize: '16px' }}/>
                    </ButtonTooltip>
                :
                    (this.props.buttonType?.type === "warnInvoicesIcon") ?
                        <ButtonTooltip title={this.props.buttonType?.caption}>
                            <WarningTwoToneIcon style={{ color: '#FE0000', fontSize: '16px' }}/>
                        </ButtonTooltip>
                    :
                    <></>
                }
            </div>
        )
    }
}

class NumberIconedComponentCreator extends DefaultComponentCreator {
    constructor(precision) {
        super()
        this.precision = precision || 0;
    }

    createWith(data) {
        return (
            <NumberIconedComponent currencySymbol={data.symbol} precision={this.precision} buttonType={data.buttonType} value={data.value}/>
        )
    }

}

class PasswordComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPassword: false,
        }

        this.yPos = '50%';
    }

    handleClickShowPassword = (show) => {
        this.setState({showPassword: show})
    };

    render() {
        return (
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                <IconButton
                    onClick={event => {
                        event.stopPropagation()
                        this.handleClickShowPassword(!this.state.showPassword)

                    }}
                    edge="end"
                >
                    {this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
                <span style={{ marginLeft: 15, whiteSpace: 'nowrap'}}>
                    { this.state.showPassword ? this.props.value : "***********"}
                </span>
            </div>
        )
    }
}

class PasswordComponentCreator extends DefaultComponentCreator {
    constructor() {
        super()
    }

    createWith(data) {
        return (
            <PasswordComponent value={data}/>
        )
    }

}

const chipUseStyles = makeStyles(theme => (
    {
        chip: {
            '&.MuiChip-root': {
                height: 32,
            }
        }
    })
);

function CustomChip(props) {
    const { label, ptc, color, translate} = props;
    const { t} = useTranslation()
    const classes = chipUseStyles();

    return(
        <div>
            <Chip
                className={classes.chip}
                style={{margin:2}}
                color={color}
                size={'medium'}
                variant='outlined'
                avatar={ptc ? 
                    <Avatar
                        style={{fontSize: 12, height: '24px', minWidth: '18px', width: 'auto', display: 'flex', justifyContent: 'center', padding: '0 4px'}}>{`${ptc}%`}</Avatar>:null}
                label={translate ? t(label) : label}
            />
        </div>
    )
}


const composedChipStyle = {
    margin:2,
    backgroundColor:'#075e54',
    "&:hover": {
        backgroundColor: "#a7cbc7"
    }
};

function ComposedChips (props) {
    function handleOpenPopper(event){
        event.stopPropagation()
        setAnchorIcon(event.target)
    }
    function handleClosePopper(event){
        event.stopPropagation()
        setAnchorIcon(null)
    }
    const [anchorIcon, setAnchorIcon] = useState(null)
    const { chips, label } = props;
    const { t } = useTranslation();
    const color = !props.customStyle ? {color:"primary"} : null
    const customStyle = props.customStyle ? props.customStyle : null

    return(
        <div>
            <Chip
                style={props.label ? customStyle : composedChipStyle}
                size='small'
                {...color}
                onClick={handleOpenPopper}
                label={props.label ? props.label: t('Multiple Association')}
            />
            <Popover  open={Boolean(anchorIcon)}
                        onClick={handleClosePopper}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handleClosePopper}
                        disableRestoreFocus
                        anchorEl={anchorIcon}>
                <div style={{padding:20}}>
                    {chips}
                </div>
            </Popover>
        </div>
    )
}

const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}

class ChipsComponent extends React.Component {
    convertDataToStr = (association) => {
        var mappedDataString = " "
        for(let mapper of this.props.mappers){
            mappedDataString = String(mappedDataString)+" "+_.get(association, mapper)
        }
        return(mappedDataString)
    }

    render(){
        const chips = this.props.data.filter(a => a).map((a, index) => {
            const associationDisplayName = this.props.mappers ? this.convertDataToStr(a) : this.props.associationDisplayNameResolver(a);
            const unassigned = associationDisplayName == this.props.nullAssociationDisplayName || associationDisplayName == null;
            return(
                <CustomChip
                    label={!unassigned ? associationDisplayName : 'Not Assigned'}
                    key={a.id || generateKey('UnassignedAssociation_' + index) + 'chip'}
                    color={!unassigned ? 'primary' : 'secondary'}
                    ptc={this.props.showPercentage ? a.percentage : null}
                    translate={unassigned}
                />
            )
        })
        let components;
        if(chips.length < 1){
            components = <CustomChip label={'Not Assigned'} key={'UnassignedAssociation_chip'} color={'secondary'} ptc={this.props.showPercentage ? '100' : null} translate={true}/>
        } else if(chips.length < 2 && this.props.mappers){
           components = <ComposedChips label={this.props.associationDisplayNameResolver(this.props.data[0])} chips={chips}/>
        } else if (chips.length < 2){
            components = chips
        } else {
            components = <ComposedChips chips={chips}/>
        }

        return (
            <div style={{overflow: 'hidden'}}>
                {components}
            </div>
        )

    }
}

class ConsolidatedSBOChipsComponent extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            associoations :this.props.data
        }
    }
    render(){
        const chips = this.state.associoations.map((a, index) => {
            const associationDisplayName = a
            const unassigned = associationDisplayName == this.props.nullAssociationDisplayName;
            return(
                <CustomChip
                    label={!unassigned ? associationDisplayName : 'Not Assigned'}
                    key={a || generateKey('UnassignedAssociation_' + index) + 'chip'}
                    color={!unassigned ? 'primary' : 'secondary'}
                    translate={true}/>
            )
        })
        let components;
        components = <ComposedChips customStyle={this.props.customStyle} label={this.props.associationDisplayNameResolver} chips={chips}/>


        return (
            <div style={{overflow: 'hidden', margin:"2%"}}>
                {components}
            </div>
        )

    }
}

class ConsolidatedSBOChipComponentCreator extends DefaultComponentCreator{
     createWith(data){
        const periodChipStyle = {
            margin:2,
            backgroundColor:"#0a1f2e",
            color:"white",
            "&:hover": {
                backgroundColor: "#a7cbc7"
            }
        };
        const vendorChipStyle = {
            margin:2,
            backgroundColor:"#55758C",
            color:"white",
            "&:hover": {
                backgroundColor: "#a7cbc7"
            }
        };

        return(
            <>
            {data ?
            <Grid container>
                <Grid xs={5} item>
                    <ConsolidatedSBOChipsComponent
                    nullAssociationDisplayName={null}
                    associationDisplayNameResolver={"Vendors"}
                    data={data["vendors"]}
                    customStyle={vendorChipStyle} />
                </Grid>
                <Grid xs={5} item>
                    <ConsolidatedSBOChipsComponent
                    nullAssociationDisplayName={null}
                    associationDisplayNameResolver={"Periods"}
                    data={data["periods"]}
                    customStyle={periodChipStyle} />
                </Grid>
            </Grid>
            : null}
            </>
        )
    }


}

class GeneralChipComponentCreator extends DefaultComponentCreator {
    constructor(mappers,innerMapper, defaultLabel=null, showPercentage=true){
        super()
        this.mappers = mappers
        this.innerMapper = innerMapper
        this.defaultLabel = defaultLabel
        this.showPercentage = showPercentage
    }

    mapperCreator(data){
        let dataStr = ""
        let arrayData = []

        if (Array.isArray(data)) {
            arrayData = data
        } else {
            arrayData = [data]
        }

        for(let item of arrayData){
            for(let mapper of this.mappers){
                if(dataStr  == ""){
                    dataStr = _.get(item, mapper);
                } else {
                    dataStr = dataStr + " - " + _.get(item, mapper)
                }
            }
        }

        if (!dataStr) {
            dataStr = this.defaultLabel
        }

        return dataStr
    }

    createWith(data){
        return(
            <ChipsComponent
                nullAssociationDisplayName={'Not Assigned'}
                associationDisplayNameResolver={(data) => data ? this.mapperCreator(data) : null}
                showPercentage={this.showPercentage && this.innerMapper==null}
                mappers={this.innerMapper}
                data={data||[]}
                value={this.mapperCreator(data)}
            />
        )
    }
}

class StatusComponent extends React.Component{
    getSVG(){
        const value = String(this.props.value)
        if (value === '' || value === 'null' || value === 'undefined') {
            return nullSVG
        } else {
            return value === "true" ? activeSVG : inactiveSVG
        }
    }

    render(){
        return(
            <> 
                { this.getSVG() }
            </>
        )
    }
}

class StatusComponentCreator extends DefaultComponentCreator {
    createWith(data){
        return(
            <StatusComponent value={data} />
        )
    }
}

class ThousandSeparatorComponent extends React.Component{

    render(){
        const number = Number(Number(this.props.value).toFixed(2))
        return(
            <div style={{textAlign:"right",paddingRight:"33%"}}>
                <span>{thousandSeparator(number,this.props.digits)}</span>
            </div>
        )
    }
}

class ThousandSeparatorCreator extends DefaultComponentCreator {
    constructor(digits){
        super()
        this.digits = digits || null
    }
    createWith(data) {
        return (
            <ThousandSeparatorComponent digits={this.digits} value={data}/>
        )
    }
}

const periodToExcelTransformer = (periodStr) =>{
    const periodArray = periodStr.split("-")
    // 48 secs are added due to a bug in sheetJs
    const date = new Date(Number(periodArray[0]),Number(periodArray[1]) - 1 ,Number(periodArray[2]),0,0,48)
    return date
}

class DataTransformer{

    constructor(transformer, stateMapper, defaultTransformerValue=""){
       this.transformerFunction =  transformer || this.defaultNullFunction;
       this.stateMapper = stateMapper || this.formDataProcessor;
       this.defaultTransformerValue = defaultTransformerValue;
    }

    transform = data => {
        if (!data && typeof(data) != "boolean"){
             return this.defaultTransformerValue;
        };
        return this.transformerFunction(data)
    }

    stateTransformer = data => {
        return this.stateMapper(data)
    }

    formDataProcessor = data => {
        if (data && data.id){
            return data.id;
        }
        return data;
    }

    excelDataProcessor = data => {
        if (!data){
            return ''
        }else if(typeof(this.transformerFunction(data)) =="object"){
            return this.transformerFunction(data)
        }else{
            return this.transformerFunction(data)
        }
    }

    defaultNullFunction = (data) => {
        let res =  [];
        Object.values(data).map(str =>{
            res.push(str);
        });
        return res.join(" ")
    };

}

class ClickStrategy {
    shouldShowCursor() {
        return true;
    }

    createPopUpWith(client,TableObject,clickedId, popupDisplay, handleSetFilters, hidePopup){
    }

}

class NullClickStrategy extends ClickStrategy {
    doFor = (table, data) => {
    }

    shouldShowCursor() {
        return false;
    }

    createPopUpWith(client,TableObject,clickedId, popupDisplay, handleSetFilters, hidePopup){
    }
}

class DefaultClickStrategy extends ClickStrategy {
    doFor = (table, data) => {
        table.showPopupFor(data.id);
    }
    createPopUpWith(client,TableObject,clickedId, popupDisplay, handleSetFilters, hidePopup, classes){
        return React.createElement(PopUpData,{client:client,
            TableObject:TableObject,
            id:clickedId,
            openD:popupDisplay,
            handleSetFilters: handleSetFilters,
            close:hidePopup,
            classes:classes,
            style:{zindez:99999999},
            })
    }
}

class InventoryClickStrategy extends DefaultClickStrategy {
    createPopUpWith(client,TableObject,clickedId, popupDisplay, handleSetFilters, hidePopup, classes){
        return React.createElement(PopUpData,{client:client,
            TableObject:TableObject,
            id:clickedId,
            openD:popupDisplay,
            handleSetFilters: handleSetFilters,
            close:hidePopup,
            classes:classes,
            inventoryAssociations: true,
            style:{zindez:99999999},
            })
    }
}

class InvoiceClickStrategy extends ClickStrategy {
    doFor = (table, data) => {
        table.props.history.push("validate/" + data.id)
    }

}


class InvoicePaymentGroupClickStrategy extends ClickStrategy{
    doFor = (table, data) => {
        table.showPopupFor(data.id);
    }
    createPopUpWith(client,TableObject,clickedId, popupDisplay, handleSetFilters, hidePopup, classes){
        return React.createElement(InvoicePaymentGroupPopUp,{client:client,
            TableObject:TableObject,
            id:clickedId,
            openD:popupDisplay,
            handleSetFilters: handleSetFilters,
            close:hidePopup,
            classes:classes,
            style:{zindez:99999999},
            })
    }
}

class DefaultDisplayStrategy {
    doFor = (table) => {
        return null
    }
}

class HideMassiveUploadsDisplayStrategy{
    doFor = (table) => {
        table.hideMassiveUploadsButton();
    }
}

class TablePickerClickStrategy extends ClickStrategy{
    constructor( stateHandler ){
        super()
        this.stateHandler = stateHandler;
    }

    doFor = (enhanceTable, data) => {
        this.stateHandler(data);
    }
}

class TableMultiPickerClickStrategy extends ClickStrategy{
    constructor( stateHandler ){
        super()
        this.stateHandler = stateHandler;
    }

    doFor = (enhanceTable, data, objectData) => {
        this.stateHandler(objectData);
    }
}

class TablePickerModelClikStrategy extends ClickStrategy{
    constructor( stateHandler ){
        super()
        this.stateHandler = stateHandler;
    }

    doFor = (enhanceTable, data, objectData) => {
        this.stateHandler(objectData);
    }
}

class DefaultTableNodeDataGetter{
    mapWith(data, mapper){
        const rows = [];
        Object.values(data)[0].edges.map(
            row_data =>{
                row_data = row_data.node;
                rows.push(mapper(row_data))
            }
        )
        return rows;
    }
}

class DefaultObjectNodeGetter{
    mapWith(data, mapper){
        const rows = [];
        Object.values(data)[0].edges.map(
            row_data =>{
                row_data = row_data.node;
                const rowDict = {data:mapper(row_data), objectData: row_data}
                rows.push(rowDict)
            }
        )
        return rows;
    }
}

class CustomTableRowMapperDataGetter{
    constructor(mapper){
        this.mapper = mapper
    }
    mapWith(data, mapper){
        return this.mapper(data, mapper)
    }
}

class TablePickerDisplayStrategy {
    constructor(props={}){
        this.headerStyles = props.headerStyles || ""
        this.bodyStyles = props.bodyStyles || ""

    }
    doFor = (enhanceTable) => {
        enhanceTable.hideFooter();
        enhanceTable.hideCheckbox();
        enhanceTable.hideEditButton();
        enhanceTable.setCustomBodyWrapperStyles(this.bodyStyles);
        enhanceTable.setCustomHeaderStyles(this.headerStyles);
    }
}

class TableMultiPickerDisplayStrategy {
    constructor(props={}){
        this.headerStyles = props.headerStyles || ""
        this.bodyStyles = props.bodyStyles || ""

    }
    doFor = (enhanceTable) => {
        enhanceTable.hideFooter();
        enhanceTable.hideCheckbox();
        enhanceTable.hideEditButton();
        enhanceTable.setCustomBodyWrapperStyles(this.bodyStyles);
        enhanceTable.setCustomHeaderStyles(this.headerStyles);
    }
}


class InventoryValidationDisplayStrategy {
    constructor(props={}){
        this.headerStyles = props.headerStyles || ""
        this.bodyStyles = props.bodyStyles || ""

    }
    doFor = (enhanceTable) => {
        enhanceTable.hideEditButton();
        enhanceTable.hideNewButton();
        enhanceTable.setCustomBodyWrapperStyles(this.bodyStyles);
        enhanceTable.setCustomHeaderStyles(this.headerStyles);
    }
}

class HideDeletionDisplayStrategy {
    doFor = (enhanceTable) => {
        enhanceTable.hideCheckbox();
    }
}

class MassiveUploadsDisplayStrategy {
    doFor = (enhanceTable) => {
        enhanceTable.hideCheckbox();
        enhanceTable.hideEditButton();
        enhanceTable.hideNewButton();
        enhanceTable.hideFilters();
    }
}

class OnlyFilterActionDisplayStrategy {
    doFor = (enhanceTable) => {
        enhanceTable.hideCheckbox();
        enhanceTable.hideEditButton();
        enhanceTable.hideNewButton();
    }
}

class TableMapperCreator {
    create = () => null;
    addDescriptions = () => null;
}

class InvoiceDisplayStrategy {
    doFor = (enhanceTable) => {
        enhanceTable.hideEditButton();
        enhanceTable.hideNewButton();
    }
}

class RankingDisplayStrategy {
    doFor = (enhanceTable) => {
        enhanceTable.hideCheckbox();
        enhanceTable.hideEditButton();
        enhanceTable.hideNewButton();
        enhanceTable.hidePreColumn();
    }
}

class PeriodDisplayStrategy {
    doFor = (enhanceTable) => {
        enhanceTable.hideEditButton();
    }
}

class HideNewDisplayStrategy {
    doFor = (enhanceTable) => {
        enhanceTable.hideNewButton();
    }
}

class customPopUpContentDisplayStrategy {
    constructor(component){
        this.component = component
    }
    doFor(props, _tableObject){
        return React.createElement(this.component,{data:props.data,
                                                   TableObject:_tableObject,
                                                   refetch:props.refetch,
                                                   close:props.close,
                                                   handleSetFilters: props.handleSetFilters
                                                   })
    }

}
class DefaultPopupContentDisplayStrategy{
    doFor(props, _tableObject){
        return React.createElement(PopupContent,{data:props.data,
                                                 TableObject:_tableObject})
    }
}

class DefaultFormCancelStrategy{
    doFor(component, path){
        return component.props.history.push(path);
    }
}

class customFormCancelStrategy{
    constructor(strategy){
        this.strategy = strategy
    }
    doFor(formComponent, path){
        return this.strategy(formComponent, path)
    }
}
class DefaultFormConfirmStrategy{
    doFor(component, path, response){
        return component.props.history.push(path);
    }
}
class customFormConfirmStrategy{
    constructor(strategy){
        this.strategy = strategy
    }
    doFor(formComponent, path, response){
        return this.strategy(formComponent, path, response)
    }

}

class DefaultSelectedRowsActionsStrategy{
    doFor({
        closePopper,
        changeSelectedState,
        clearAllSelections,
        ListItemIcon,
        ListItemText,
        MenuItem,
        t,
        selectedRows,
        allRowsSelected,
        filters
    }){
            return (
                <>
                <MenuItem
                    key={'tmc_tss'}
                    onClick={
                        () => {closePopper(); changeSelectedState()}
                    }
                >
                    <ListItemIcon>
                        <ToggleOffIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("tableFooter:Toggle Selected Status")}/>
                </MenuItem>

                <MenuItem key={'tmc_csc'} onClick={() => {closePopper(); clearAllSelections()}}>
                    <ListItemIcon>
                        <CheckBoxOutlineBlankIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("tableFooter:Clear Selected Checkboxes")} />
                </MenuItem>
                </>
            )

    }
}



const UNPUBLISH_COMPANY_PERIOD = gql `
mutation($companyPeriodId: [String], $allRecords: Boolean, $filters: String){
    unpublishCompanyPeriod(companyPeriodId:$companyPeriodId, allRecords: $allRecords, filters: $filters){
        companyPeriods {
            id
            period {
              date
            }
          }
  }
}`;

const PUBLISH_COMPANY_PERIOD = gql `
mutation($companyPeriodId: [String], $allRecords: Boolean, $filters: String){
    publishCompanyPeriod(companyPeriodId:$companyPeriodId, allRecords: $allRecords, filters: $filters){
        companyPeriods {
            id
            period {
              date
            }
          }
  }
}`;


const VALIDATE_INVOICES = gql `
mutation($ids : [String], $allRecords: Boolean, $filters: String){
  validateInvoices(ids: $ids, allRecords: $allRecords, filters: $filters){
    invoice{
      id
    }
  }
}`;

function InvoiceSelectComponent(props){
    const client = useApolloClient()
    const [message,setMessage]=useState("")
    const [actions,setActions] = useState(false)
    const { closePopper,
            clearAllSelections,
            ListItemIcon,
            ListItemText,
            MenuItem,
            t,
            selectedRows,
            handleErrorMessage,
            handleMessage,
            refreshTable,
            allRowsSelected,
            filters } = props

    const mutateSelectedInvoices =(props)=>{
        let ids = []
        for(let data of props.selectedRows){
            ids.push(data.id)
        }
        client.mutate({
            mutation: props.mutation,
            variables : {ids: ids, allRecords: allRowsSelected, filters: JSON.stringify(filters)}
        }).then(
            res => {
                refreshTable()
                handleMessage("Done");
                closePopper();
            }
        ).catch(
            err =>{
                handleErrorMessage(String(err));
            }
        )
    }

    const handleAction = (mutation) =>{
        clearAllSelections();
        mutateSelectedInvoices({selectedRows:selectedRows, mutation:mutation,closePopper:closePopper,clearAllSelections :clearAllSelections})
        closePopper();
    }

    let invoiceStates = new Set()
    for(let invoiceData of selectedRows){
        invoiceStates.add(invoiceData.state)
    }

    if((allRowsSelected || invoiceStates.size == 1) && !actions){
        if(!invoiceStates.has("VALIDATED")){
            setActions("validate")
        }
    }

    return (
        <>

        <MenuItem onClick={() => {closePopper();clearAllSelections() }}>
            <ListItemIcon>
                <CheckBoxOutlineBlankIcon />
            </ListItemIcon>
            <ListItemText primary={t("tableFooter:Clear Selected Checkboxes")} />
        </MenuItem>

        {actions == "validate" ? <MenuItem onClick={() =>handleAction(VALIDATE_INVOICES)}>
            <ListItemIcon>
                <DoneIcon />
            </ListItemIcon>
            <ListItemText primary={t("tableFooter:Validate Selected Invoices")} />
        </MenuItem>
        : null }

        </>
    )


}

class InvoiceSelectedActions{
    doFor({
        closePopper,
        changeSelectedState,
        clearAllSelections,
        ListItemIcon,
        ListItemText,
        MenuItem,
        t,
        selectedRows,
        allRowsSelected,
        filters,
        handleSetFilters,
        handleMessage,
        handleErrorMessage,
        refreshTable
    }){
        return (
                <InvoiceSelectComponent
                closePopper={closePopper}
                clearAllSelections={clearAllSelections}
                ListItemIcon={ListItemIcon}
                ListItemText={ListItemText}
                MenuItem={MenuItem}
                t={t}
                selectedRows={selectedRows}
                allRowsSelected = {allRowsSelected}
                filters={filters}
                handleMessage={handleMessage}
                handleErrorMessage={handleErrorMessage}
                refreshTable={refreshTable}
                />
        )

    }
}

class ValidateSelectedInventoriesStrategy{
    constructor(validateHandler){
        this.validateHandler = validateHandler;
    }
    doFor({
        closePopper,
        changeSelectedState,
        clearAllSelections,
        ListItemIcon,
        ListItemText,
        MenuItem,
        t,
        selectedRows,
        filters,
        allRowsSelected,
        handleSetFilters,
        refreshTable
        //selectAllRows
    }){
        const ids = []
        for(let data of selectedRows){
            ids.push(''+data.id)
        }
            return (
              <>
                <ApolloConsumer>
                    {client => (
                        <MenuItem onClick={() => this.validateHandler({selectedRows: ids, filters: filters, allRowsSelected: allRowsSelected, closePopper, clearAllSelections, handleSetFilters, client, refreshTable})}>
                            <ListItemIcon>
                                <ThumbUpIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("tableFooter: Validate Selected Inventories")}/>
                        </MenuItem>
                    )}
                </ApolloConsumer>

                <MenuItem onClick={() => {closePopper(); clearAllSelections()}}>
                    <ListItemIcon>
                        <CheckBoxOutlineBlankIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("tableFooter:Clear Selected Checkboxes")} />
                </MenuItem>
              </>
            )

    }
}

const FIRST_LAST_PERIOD_QUERY = gql`
    query {
        firstPublishedPeriod {
            period {
                startDate
            }
        }
        lastPublishedPeriod {
            period {
                startDate
            }
        }
    }
`

function PeriodSelectComponent(props){
    const client = useApolloClient()
    const dispatch = useDispatch()

    const { closePopper,
            clearAllSelections,
            ListItemIcon,
            ListItemText,
            MenuItem,
            t,
            selectedRows,
            filters,
            allRowsSelected,
            handleMessage,
            handleErrorMessage,
            refreshTable } = props

    const updateFirstLastPublishedPeriod = () =>{
        try {
            client.query({
                query: FIRST_LAST_PERIOD_QUERY,
                fetchPolicy: 'network-only',
            })
            .then(res => {
                dispatch(setFirstPublishedPeriod(res.data.firstPublishedPeriod))
                dispatch(setLastPublishedPeriod(res.data.lastPublishedPeriod))
            })
        } catch (error) {
            console.log(error)
        }
    }

    const mutateSelectedPeriod =(props)=>{
        let ids = []
        for(let data of props.selectedRows){
            ids.push(data.id)
        }
        client.mutate({
            mutation: props.mutation,
            variables : {companyPeriodId : ids, allRecords: allRowsSelected, filters: JSON.stringify(filters)}
        }).then(
            res => {
                handleMessage('Done');
                refreshTable();
                closePopper();
                updateFirstLastPublishedPeriod();
            }
        ).catch(
            err =>{
                handleErrorMessage(String(err));
            }
        )

    }

    const handleAction = (mutation) =>{
        closePopper();
        clearAllSelections();
        mutateSelectedPeriod({selectedRows:selectedRows, mutation:mutation,closePopper:closePopper,clearAllSelections :clearAllSelections})
    }

    let periodStates = new Set()
    for(let periodData of selectedRows){
        periodStates.add(periodData.state)
    }

    const optionIsEnabled = (action) =>{
        return allRowsSelected ? true : periodStates.size == 1 && !periodStates.has(action)
    }

    return (
        <>
            <MenuItem key={'mnu_item_clear'} onClick={() => {closePopper(); clearAllSelections()}}>
                <ListItemIcon>
                    <CheckBoxOutlineBlankIcon />
                </ListItemIcon>
                <ListItemText primary={t("tableFooter:Clear Selected Checkboxes")} />
            </MenuItem>

            {optionIsEnabled("Unpublished") ? <MenuItem key={'mnu_item_unpublish'} onClick={() => {handleAction(UNPUBLISH_COMPANY_PERIOD)}}>
                <ListItemIcon>
                    <ClearIcon />
                </ListItemIcon>
                <ListItemText primary={t("tableFooter: Unpublish Selected Periods")} />
            </MenuItem>
            : null}
            {optionIsEnabled("Published") ? <MenuItem key={'mnu_item_publish'} onClick={() =>handleAction(PUBLISH_COMPANY_PERIOD)}>
                <ListItemIcon>
                    <DoneIcon />
                </ListItemIcon>
                <ListItemText primary={t("tableFooter:Publish Selected Periods")} />
            </MenuItem>
            : null}
        </>
    )

}

class PeriodSelectedActions{
    doFor({
        closePopper,
        changeSelectedState,
        clearAllSelections,
        ListItemIcon,
        ListItemText,
        MenuItem,
        t,
        selectedRows,
        allRowsSelected,
        filters,
        handleSetFilters,
        handleErrorMessage,
        handleMessage,
        refreshTable
    }){
        return (
            <PeriodSelectComponent
                closePopper={closePopper}
                clearAllSelections={clearAllSelections}
                ListItemIcon={ListItemIcon}
                ListItemText={ListItemText}
                MenuItem={MenuItem}
                t={t}
                selectedRows={selectedRows}
                allRowsSelected={allRowsSelected}
                filters={filters}
                handleErrorMessage={handleErrorMessage}
                handleMessage={handleMessage}
                refreshTable={refreshTable}
            />
        )
    }
}

const excelComaSeparator= (data,mapper) => {
    //data parameter must an array
    let mappedData = ""
    for(let individualData of data){
        individualData = individualData == data[0] ? individualData[mapper] : `, ${individualData[mapper]}`
        mappedData = mappedData + individualData
    }
    return mappedData
}

export{
    TableMapperCreator,
    TableFieldDescription,
    DataTransformer,
    TablePickerClickStrategy,
    DefaultClickStrategy,
    InventoryClickStrategy,
    TablePickerDisplayStrategy ,
    TableMultiPickerDisplayStrategy,
    MassiveUploadsDisplayStrategy,
    FormSection,
    TableColumSection,
    InvoiceDisplayStrategy,
    customPopUpContentDisplayStrategy,
    customFormConfirmStrategy,
    customFormCancelStrategy,
    FormCreator,
    FormFieldDescription,
    TableCreator,
    DataMapper,
    InvoiceClickStrategy,
    HideDeletionDisplayStrategy,
    OnlyFilterActionDisplayStrategy,
    ValidateSelectedInventoriesStrategy,
    InventoryValidationDisplayStrategy,
    HideMassiveUploadsDisplayStrategy,
    CustomTableRowMapperDataGetter,
    NullClickStrategy,
    NumberComponentCreator,
    NumberComponent,
    NumberIconedComponentCreator,
    NumberIconedComponent,
    PasswordComponentCreator,
    PasswordComponent,
    ThousandSeparatorCreator,
    StatusComponentCreator,
    PeriodDisplayStrategy,
    CustomChip,
    InvoiceSelectedActions,
    PeriodSelectedActions,
    periodToExcelTransformer,
    GeneralChipComponentCreator,
    excelComaSeparator,
    BooleanExcelDisplayCreator,
    ManagerExcelDisplayCreator,
    GeneralExcelDisplayCreator,
    DefaultComponentCreator,
    DefaultComponent,
    InvoicePaymentGroupClickStrategy,
    ConsolidatedSBOChipComponentCreator,
    TablePickerModelClikStrategy,
    TableMultiPickerClickStrategy,
    HideNewDisplayStrategy,
    RankingDisplayStrategy,
}
