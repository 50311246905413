import React, {useState} from "react"
import { Dialog, Button, IconButton} from '@material-ui/core';
import {DefaultComponentCreator} from "../../TableMapperCreator"

function PopUpComponent(props) {
    const [anchorEl,setAnchorEl] = useState(null) 
    const [disabled, setDisabled] = useState(false)
    const handleClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget)
    }
    const handleClose = (e) =>{
        e.stopPropagation();
        setAnchorEl(null)
    }

    const handleDisable = () => {
        setDisabled(true)
    }

    return(
        <div onClick={e => e.stopPropagation()}>
            <span>
                {props.buttonType === "iconButton" ? 
                <IconButton disabled={disabled} size="small" onClick={e => handleClick(e)} >{React.createElement(props.labelComponent, {rowData:props.rowData, data: props.data, disable: handleDisable})}</IconButton>
                    :
                <Button style={{fontSize:12}} onClick={e => handleClick(e)} >{React.createElement(props.labelComponent, {rowData:props.rowData, data:props.data,disable:handleDisable})}</Button>
                 }
            </span>
            <Dialog
            open={Boolean(anchorEl)}
            maxWidth='lg' 
            onClose={handleClose}>
                <div style={{ height: '70vh', margin: 'auto' }}>
                    {props.children(handleClose)}
                </div>
            </Dialog>
        </div>
    )
}


class PopUpComponentCreator extends DefaultComponentCreator{
    constructor(labelComponent, childrenComponent, popUpProps, type){
        super()
        this.labelComponent = labelComponent
        this.children = childrenComponent
        this.popUpProps = popUpProps
        this.type = type || null
    }

    createWith(data, rowData){
        let showData = true
        if(this.popUpProps && this.popUpProps.showData){
            showData = this.popUpProps.showData(data)
        }
        if(showData){
            return(
                <PopUpComponent buttonType={this.type} labelComponent={this.labelComponent} data={data.value} rowData={rowData}>
                    {(handleClose)=> React.createElement(this.children, { rowData:rowData, popupData: data.popupData, handleClose: handleClose, ...this.popUpProps })}
                </PopUpComponent>
            )
        }
    }
}

export default PopUpComponentCreator
