/* eslint-disable eqeqeq */
/* eslint-disable import/first */
import React, { Component } from 'react'
import { renderToString } from 'react-dom/server';
import ChargeGroupsFilters from "./chargeFilters"
import CanvasJSReact from "../../utils/canvasjs.react"
import bubbleColors from "../../utils/constants"
import {quantityTransformer, thousandSeparator} from '../../utils/tools'

var CanvasJSChart = CanvasJSReact.CanvasJSChart
import AveragesCard from "./AveragesCard"


class GraphDrawer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        filters: null
        }
    }

    toolTipContentFormatter = (toolTipContent) => {
        var totalValue = 0.0;

        function incTotalValue(aValue) {
            totalValue += aValue;
        }
    
        if (toolTipContent.entries.length > 0) {
            let classGrid = "row grid grid-cols grid-flow-col auto-cols-min";

            if (toolTipContent.entries.length < 20) {
                classGrid = "ToolTipDetail";
            }

            return renderToString(
                <div className="container">
                    <div key={"tooltipHeader"} style={{paddingBottom: '7px'}}>
                        <strong>{toolTipContent.entries[0].dataPoint.label}</strong><br/>
                    </div>
                    <div className ={classGrid}>
                        {toolTipContent.entries.map((entry) => (
                            <div key={'_'+entry.dataPoint.y} style={{ paddingLeft: '15px' }}>
                                {incTotalValue(entry.dataPoint.y)}
                                <div style={{ display: "inline-block", backgroundColor: entry.dataSeries.color, width: "12px", height: "12px" }}></div>
                                {" " + entry.dataSeries.name + ": "}
                                <strong>{toolTipContent.chart.axisY[0].prefix} {thousandSeparator(entry.dataPoint.y)}</strong><br />
                            </div>                        
                        ))}
                    </div>
                    <div key={"tooltipFooter"} style={{paddingTop: '7px'}}>
                        <strong>{"Total: "} {toolTipContent.chart.axisY[0].prefix} {thousandSeparator(totalValue)}</strong><br/>
                    </div>
                </div>
            )    
        }
        else {
            return "";
        }
    }
    
    handleSetFilters = (f) => {
        this.setState({filters: f})
    }

    render() {
        const data = this.props.data;
        const {t} = this.props
        const dataByTech = data.expenseByTechnology;

        var expenseByTechnology = [];
        for (let techData of dataByTech.dataByTechnology) {
            if (techData.totalCost !== 0) {
                expenseByTechnology.push({
                    label: techData.technology,
                    y: techData.totalCost,
                    s:quantityTransformer(techData.totalCost),
                    t:thousandSeparator(techData.totalCost),
                }) 
            }
        }

        const expenseByTechnologyDonutChart = {
            animationEnabled: true,
            colorSet:"bubbleColors",
            title: {
                text: this.props.dateTitle,
                fontSize: 20,
                fontFamily:"Calibri Light",
                fontColor:"#131f2e",
                verticalAlign: "top",
                horizontalAlign: "left",
            },
            subtitles: [{
                text: t("Expense by technology"),
                fontSize: 15,
                verticalAlign: "top",
                horizontalAlign: "left",
                fontFamily:"Calibri Light",
                fontColor:"#131f2e",
            }],
            data: [{
                type: "doughnut",
                startAngle: 270,
                innerRadius: 60,
                yValueFormatString: this.props.currencySymbol+"# (#percent%)",
                indexLabel: "{label} = "+this.props.currencySymbol+"{s}       (#percent%)",
                toolTipContent:"{label}: "+this.props.currencySymbol+"{t} (#percent%)",
                dataPoints: expenseByTechnology.sort((item_a, item_b) => (item_a.y > item_b.y) ? -1 : ((item_b.y < item_a.y) ? 1 : 0)), //Descending Sort
            }]
        }

        const firstPeriod = data.expenseByVendor.dataByPeriod[0];
        var vendorsTotals = [];
        for (let vendorData of firstPeriod.dataByVendor) {
            if (vendorData.totalCost !== 0) {
                vendorsTotals.push({
                    label: vendorData.vendor.name,
                    y: vendorData.totalCost,
                    s:quantityTransformer(vendorData.totalCost),
                    t:thousandSeparator(vendorData.totalCost),
                }) 
            }
        }

        vendorsTotals = vendorsTotals.sort((item_a, item_b) => (item_a.y > item_b.y) ? -1 : ((item_b.y < item_a.y) ? 1 : 0)); //Descending Sort

        const pieChartSettings = {
            colorSet:"bubbleColors",
            title: {
                text: this.props.dateTitle,
                fontSize: 20,
                fontFamily:"Calibri Light",
                fontColor:"#ffffff",
                verticalAlign: "top",
                horizontalAlign: "left",
            },
            subtitles: [{
                text: t("Total expense by vendor"),
                fontSize: 15,
                verticalAlign: "top",
                horizontalAlign: "left",
                fontFamily:"Calibri Light",
                fontColor:"#131f2e",
            }],
            data: [{
                type: "pie",
                startAngle: 270,
                yValueFormatString: this.props.currencySymbol+"#",
                indexLabel: "{label} "+this.props.currencySymbol+"{s}",
                toolTipContent:"{label}: "+this.props.currencySymbol+"{t}",
                dataPoints: vendorsTotals,
            }]
        }

        const allCharges = {}
        for (let periodData of data.expenseByVendor.dataByPeriod) {
            for (let vendorData of periodData.dataByVendor) {
                for (let chargeData of vendorData.dataByChargeGroup) {
                    allCharges[chargeData.chargeGroup.id] = {label:chargeData.chargeGroup.name}
                }
            }
        }

        function toggleDataSeries(e) {
            if(typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            }
            else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        }

        var vendorsDetails = {};
        for (let vendorData of firstPeriod.dataByVendor) {
            for (let chargeData of vendorData.dataByChargeGroup) {
                let chargeDataName = chargeData.chargeGroup.name;
                if(chargeData.cost !==0){
                    if (!vendorsDetails.hasOwnProperty(chargeDataName)) {
                        vendorsDetails[chargeDataName] = {};
                    }

                    vendorsDetails[chargeDataName][vendorData.vendor.name] = chargeData.cost;
                }
            }
        }
        
        var vendorNamesBGD = vendorsTotals.slice(0, 10).map(vendor => vendor.label);

        var barGraphData = [];
        Object.entries(vendorsDetails).forEach(([chargeName, VendorsCharges]) => {
            const dataPoints = [];
            for (let vendorName of vendorNamesBGD) {
                if (VendorsCharges[vendorName]) {
                    dataPoints.push({label: vendorName, y: VendorsCharges[vendorName], t:thousandSeparator(VendorsCharges[vendorName])});
                } else {
                    dataPoints.push({label: vendorName, y: null})
                }
                
            }
            
            barGraphData.push({
                type: "stackedBar",
                name: chargeName,
                showInLegend: "true",
                yValueFormatString: this.props.currencySymbol+"#",
                dataPoints: dataPoints,
            });
        })

        const barGraphSettings = {
            colorSet:"bubbleColors",
            subtitles: [{
                text: t("Total expense by vendor and spend category (Top 10)"),
                padding:15,
                fontSize: 15,
                verticalAlign: "top",
                horizontalAlign: "left",
                fontFamily: "Calibri Light",
            }],
            axisY: {
                prefix: this.props.currencySymbol,
                valueFormatString:"#0,.k"
            },
            toolTip: {
                shared: true,
                contentFormatter: this.toolTipContentFormatter
            },
            legend:{
                cursor: "pointer",
                itemclick: toggleDataSeries
            },
            data: barGraphData,
        }

        const stackedData = []
        const allVendors = new Set()
        for (let dbp of (data.expenseByVendor.dataByPeriod)) {
            for (let dbv of dbp.dataByVendor) {
                allVendors.add({vendor:dbv,period:dbp.period})
            }
        }

        const allVendorData = new Set()
        for (let dbv of allVendors) {
            allVendorData.add(JSON.stringify({name:dbv.vendor.vendor.name,id:dbv.vendor.vendor.id}))
        }

        let pos = 0
        for (var _vendor of allVendorData) {
            const allDataPoints = []
            for (let v of allVendors) {
                let allChargeGroupsCost = 0
                for (let cd of v.vendor.dataByChargeGroup) {
                    for (let f in this.state.filters) {
                        if((f == cd.chargeGroup.name) && this.state.filters[f]) {
                            allChargeGroupsCost+= cd.cost
                        }
                    }
                }
                if (v.vendor.vendor.id == JSON.parse(_vendor).id) {
                    const dateArray = v.period.split("-")
                    const periodDate = dateArray[0]+"-"+dateArray[1]
                    allDataPoints.push({label:periodDate, y:allChargeGroupsCost, color:bubbleColors[pos % bubbleColors.length], t:thousandSeparator(allChargeGroupsCost)})
                }
            } 
            stackedData.push({
                type: "stackedColumn",
                name:JSON.parse(_vendor).name,
                showInLegend: true,
                yValueFormatString: this.props.currencySymbol+"#.",
                dataPoints : allDataPoints.reverse()
            })
            pos++;
        }
        const verticalStackedOptions = {
            animationEnabled: true,
            exportEnabled: true,
            colorSet:"bubbleColors",
            fontFamily: "Calibri Light",
            title: {
                text: t("Expense Trending"),
                fontColor:"#131F2E",
                fontFamily: "Calibri Light",
                fontSize: 20,
                verticalAlign: "top",
                horizontalAlign: "left",
            },
            subtitles: [{
                text: t("Expense by Vendor"),
                fontSize: 15,
                margin:30,
                verticalAlign: "top",
                horizontalAlign: "left",
                fontFamily: "Calibri Light",
            }],
            axisY: {
                prefix: this.props.currencySymbol,
                valueFormatString:"#0,.k",
                interlacedColor: "#e8e6e6",
            },
            axisY2: {
                margin:10,
            },
            toolTip: {
                shared: true,
                contentFormatter: this.toolTipContentFormatter
            },
            legend: {
                verticalAlign: "center",
                horizontalAlign: "right",
                reversed: true,
                cursor: "pointer",
                itemclick: toggleDataSeries
            },
            data:stackedData
        }

        let maxLineCount = null;
        let minLineCount = null;
        const lineCountByVendor = {}
        for (let periodData of data.totalCountByVendor) {
            for (let vendorData of periodData.dataByVendor) {
                let name = vendorData.vendor.name;

                if (!lineCountByVendor.hasOwnProperty(name)) {
                    lineCountByVendor[name] = [];
                }
                lineCountByVendor[name].push({
                    period: periodData.period,
                    count: vendorData.count,
                });

                maxLineCount = maxLineCount===null ? vendorData.count : Math.max(maxLineCount, vendorData.count);
                minLineCount = minLineCount===null ? vendorData.count : Math.min(minLineCount, vendorData.count);
            }
        }
        let lineCountSpan = maxLineCount - minLineCount;

        const lineChartData = []
        Object.entries(lineCountByVendor).forEach(([vendorName, periodsData]) => {
            lineChartData.push({
                type: "line",
                name: vendorName,
                showInLegend: true,
                dataPoints: periodsData.map(({period, count}) => ({label:period, y:count, t:thousandSeparator(count)})).reverse(),
            })     
        });

        const lineOptions = {
            animationEnabled: true,
            colorSet:"bubbleColors",
            title:{
                fontFamily: "Calibri Light",
                text: t("Count of Service Number by Vendor"),
                padding:10,
                fontSize: 20,
                fontColor:"#131F2E",
                verticalAlign: "top",
                horizontalAlign: "left",
            },
            toolTip: {
                shared: true,
                contentFormatter: this.toolTipContentFormatter
            },
            axisY: {
                prefix: "",
                interlacedColor: "#e8e6e6",
                maximum: maxLineCount !== null ? maxLineCount + lineCountSpan * 0.05 : null,
                minimum: minLineCount !== null ? minLineCount - lineCountSpan * 0.05: null,
            },
            data: lineChartData
        }

        const chargesLabels = []
        for (let cd in allCharges) {
            chargesLabels.push({label:allCharges[cd].label})
        }
        
        return (
            <div key="vec_01" id='viewExpenseContainer' className="view-expense-container p-3">
                <div key="vec_02" className="average-cards-div-container">
                    <AveragesCard title={t("Total expense")} currencySymbol={this.props.currencySymbol} data={data.totalExpense}/>
                    <AveragesCard title={t("Count of service numbers")} data={data.serviceNumbersCount}/>
                    <AveragesCard title={t("Cost per service number")} currencySymbol={this.props.currencySymbol} data={data.serviceNumbersCost}/>
                </div>

                <div key="vec_03" className="d-flex w-100 mt-3 chart-border">
                    <div key="vec_a1" className="d-flex w-100 chart-border">
                        <CanvasJSChart  options = {expenseByTechnologyDonutChart}/>
                    </div>
                    <div key="vec_a2" className="d-flex w-100 chart-border">
                        <CanvasJSChart  options = {pieChartSettings}/>
                    </div>
                </div>
                
                <div key="vec_04" className="d-flex w-100 mt-3 chart-border">
                    <CanvasJSChart  options = {barGraphSettings}/>
                </div>
                
                <div key="vec_05" className="mt-3 w-100">
                    <ChargeGroupsFilters className="d-flex" type="selection" onChange={this.handleSetFilters} data={chargesLabels} />
                    <div key="vec_06"  className="chart-border ">
                        <CanvasJSChart options = {verticalStackedOptions}/>
                        <CanvasJSChart options = {lineOptions}/>
                    </div>

                </div>
            </div>
        )
    }
}

export default GraphDrawer