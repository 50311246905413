/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import {
    TableHead, 
    Table, 
    TableRow, 
    TableCell, 
    TableBody,
    Checkbox, 
    Card, 
    Chip,
    Typography, 
    Button, 
    FormControl,
    FormControlLabel, 
    Radio, 
    RadioGroup, 
    Dialog 
} from '@material-ui/core'

import { CustomOutlinedInput as FilterInput, CustomTableCellInput as InputCell} from '../../../../components/BPMTable/BPMInputs'
import Create from "./mutations/FormQuery";
import MassiveUploadDialog from '../../../../components/BPMTable/TableComponents/Dialogs/MassiveUploadDialog'

class AssociationComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            unassignedFilter : "",
            assignedFilter : "",
            availableFilter : "",
            unassignedFields : props.unassignedFields, // [{id: "", name:"", targetId: "", targetName: ""},...]
            availableFields : props.availableFields,
            selectedTarget : null,
            selected : [],
            createDialog : false,
            massiveDialog : false,
            isLoading : false,
        }
    }

    openMassive = () => this.setState({massiveDialog:true});
    closeMassive = () => this.setState({massiveDialog:false});

    componentDidUpdate(prevProps) {
        if (this.props.availableFields.filter(this.compareDate(prevProps.availableFields))[0] &&
            this.props.availableFields !== prevProps.availableFields ) {
                const newAvailable = this.props.availableFields.filter(this.compareDate(prevProps.availableFields))[0];
                this.setState({
                        availableFilter : newAvailable.name,
                        availableFields : this.props.availableFields,
                    });
        }
    }

    compareDate = oldData => {
        return current => {
            return (
                oldData.filter(data => data.id == current.id).length == 0
            )
        }
    }

    toggleDialog = () => {
        this.setState(state => ({createDialog : !state.createDialog}))
    }

    handleFilter = event => {
        this.setState({[event.target.name] : event.target.value})
    }

    handleSetTarget = event => {
        this.setState({selectedTarget : event.target.value})
    }

    handleClickRow = id => {
        this.setState(state => {
            if (state.selected.includes(id)){
                const newSelected = state.selected.filter(_id => _id != id)
                return { selected : newSelected}
            }
            return { selected : state.selected.concat(id)}
        })
    }

    handleAssign = () => {
        this.setState( state => {
            const target = state.availableFields.find(t => t.id == state.selectedTarget);
            const unassignedFields = JSON.parse(JSON.stringify(state.unassignedFields)); // cancer  
            unassignedFields.map(field => {
                if (state.selected.includes(field.id)){
                    field.targetId = target.id;
                    field.targetName = target.name;
                }
            })
            return {unassignedFields: unassignedFields, selected: [] }
        })
    }

    handleSave = () => {
        this.setState({isLoading:true})
        const variables = this.state.unassignedFields.filter(i => i.targetId).map(f => (JSON.stringify({id:f.id,target:f.targetId})));
        const remaining = this.state.unassignedFields.filter(i => !i.targetId);

        this.props.client.mutate({
            variables: {objects : variables, invoice: this.props.invoiceId},
            mutation: this.props.mutation,
        }).then(response => {
            this.props.refetch().then(
                r => {
                    this.setState({isLoading:false, unassignedFields:remaining})
                    this.props.handleChange()
                }
            ).catch(err => {
                    console.log(err)
                    this.setState({isLoading:false})
            });
        }).catch(err => {
            console.log(err)
            this.setState({isLoading:false})
        })
    }

    clearAll = () => {
        this.setState({ unassignedFields : this.props.unassignedFields, // [{id: "", name:"", targetId: "", targetName: ""},...]
                        unassignedFilter : "",
                        assignedFilter : "",
                        availableFields : this.props.availableFields, // [{id: "", name:""},...]
                        availableFilter : "",
                        selected : [],
        })
    }

    checkAll = () =>{
        const selected = this.state.selected;
        let allSelected = true;
        this.filteredRows().map(field => {
            if (!selected.includes(field.id)){
                allSelected = false;
            }
        })
        return allSelected
    }

    handleAllCheckbox = () => {
        const selected = [];
        if (!this.checkAll()){
            this.filteredRows().map(field =>{
                selected.push(field.id)
            })
        }
        this.setState({selected: selected})
    }

    filteredRows = () => {
        const assignedFilter = this.state.assignedFilter;
        const unassignedFields = this.state.unassignedFields;
        return(
            unassignedFields.filter(desc => (
            desc.name.toLowerCase().includes(this.state.unassignedFilter.toLowerCase()))
            )
            .filter(desc =>{
                if (assignedFilter){
                    if (desc.targetName){
                        return desc.targetName.toLowerCase().includes(assignedFilter.toLowerCase())
                    }
                    return false
                }
                return true
            })
        )
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const { availableFields, unassignedFields, isLoading, selected, selectedTarget, createDialog,
               unassignedFilter, assignedFilter, availableFilter, massiveDialog} = this.state;
        const {unassignedName, targetName, classes, cancel, refetch, QUERY, FORM , t, invoiceId} = this.props;
        return (
        <div style={{width:"100%"}}>
            <div style={{display:"flex", width:"100%"}}>
                <Card raised style={{padding:10, width:"70%", height:'calc(100vh - 275px)', overflow:'auto'}} className="InvoiceAssosiationTable">
                    <Table>
                        <TableHead className={classes.tableHeadBlock}>
                            <TableRow style={{display:"table", width:"100%", height:40}}>
                                <TableCell  style={{width:"10%", padding:0,borderBottom:0, textAlign:'center'}}>
                                    <Typography>{t('fieldLabels:Select All')}</Typography> 
                                </TableCell>
                                <TableCell className={classes.tableHeadCell} style={{borderBottom:0}}>
                                    <Typography variant="h6">{unassignedName}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableHeadCell} style={{borderBottom:0}}>
                                    <Typography variant="h6">{t(targetName)}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow  style={{display:"table", width:"100%", height:40}}>
                                <InputCell style={{width:"10%", padding:0, backgroundColor:"white", borderBottom: "1px solid #e0e0e0",}}>
                                   <Checkbox color="secondary"
                                             onClick={this.handleAllCheckbox}
                                             checked={this.checkAll()} />
                                </InputCell> 
                                <InputCell  className={classes.tableHeadCell}>
                                    <FilterInput value={unassignedFilter}
                                                onChange={this.handleFilter}
                                                classes={{input:classes.filterInput}}
                                                labelWidth={0}
                                                style={{width:"100%"}}
                                                placeholder="Filter"
                                                name="unassignedFilter" />
                                </InputCell>
                                <InputCell  className={classes.tableHeadCell}>
                                    <FilterInput value={assignedFilter}
                                                onChange={this.handleFilter}
                                                classes={{input:classes.filterInput}}
                                                labelWidth={0}
                                                style={{width:"100%"}}
                                                placeholder="Filter"
                                                name="assignedFilter"/>
                                </InputCell>
                            </TableRow>
                        </TableHead>

                        <TableBody className={classes.tableBodyScroll}>
                        {
                            this.filteredRows().map(field =>(
                                <TableRow onClick={() => this.handleClickRow(field.id)}
                                        hover
                                        style={{display  :"flex"}}
                                        selected={selected.map(item => item.toLowerCase()).includes(field.id.toLowerCase())}
                                        key={field.id}>
                                    <TableCell style={{width:"10%", padding:0}}>
                                        <Checkbox checked={selected.map(item => item.toLowerCase()).includes(field.id.toLowerCase())} color="primary"/>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body2">{field.name}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{justifyContent:"center"}}>
                                            {field.targetName &&
                                                <Chip label={field.targetName}
                                                        color="primary"/>
                                            }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                </Card>
                <Card raised style={{padding:10, width:"30%", marginLeft:10, height:'calc(100vh - 275px)'}}>
                    <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
                        <div style={{display: 'flex', flexDirection: 'column', maxHeight:'calc(100vh - 110px)'}}>
                            <div style={{display:"flex", justifyContent:"space-between", top:"5px", marginBottom:10, height:'40px'}}>
                                <Typography variant="h6">{t('Assign') + ' ' +  targetName}</Typography>
                                <Button size="small"
                                        color="primary"
                                        onClick={this.toggleDialog}
                                        variant='contained'>
                                {t('Create New')}
                                </Button>
                            </div>
                            <FilterInput 
                                value={availableFilter}
                                onChange={this.handleFilter}
                                classes={{input:classes.filterInput}}
                                labelWidth={0}
                                style={{width:"100%", height:'40px'}}
                                placeholder="Filter"
                                name="availableFilter"
                            />
                            <div style={{display:"flex", marginTop:10, overflow:'auto', maxHeight:'calc(100vh - 430px)'}}>
                                <FormControl style={{width:"100%"}}>
                                    <RadioGroup 
                                        aria-label={"Available" + targetName}
                                        name="target"
                                        value={selectedTarget}
                                        // eslint-disable-next-line react/jsx-no-duplicate-props
                                        onChange={this.handleSetTarget}
                                    >
                                        {availableFields.filter(f => (f.name.toLowerCase().includes(availableFilter.toLowerCase())))
                                                        .map(field=>(
                                                <FormControlLabel style={{marginBottom:5}} key={field.id} value={field.id} control={<Radio/>} label={field.name} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{maxHeight:"110px", bottom:'80px'}}>
                            <Button size="small"
                                    color="primary"
                                    style={{width:"100%", marginTop:10}}
                                    onClick={this.handleAssign}
                                    disabled={selectedTarget == null || selected.length == 0}
                                    variant='contained'>
                            
                            {t('Assign')}
                            </Button>
                        </div>
                    </div>
                </Card>

                <div style={{width:"calc(100vw - 45px)", position:'fixed', bottom:'10px'}}>
                    <Card raised style={{padding:10, marginTop:10,
                                        height:50, 
                                        display:"flex",
                                        width:"100%",
                                        justifyContent:"space-between"}}
                    >
                        <div>
                            <Button size="small"
                                    variant='contained'
                                    color="secondary"
                                    onClick={this.clearAll}
                                    style={{marginRight: 20}} >
                                {t('Clear all')}
                            </Button>

                        </div>
                        <div>
                            <Button size="small"
                                    variant='contained'
                                    disabled={isLoading}
                                    color="secondary"
                                    onClick={this.openMassive}
                                    style={{marginRight: 20}} >
                                {t('Massive Update')}
                            </Button>
                        </div>
                        <div>
                            <Button size="small"
                                    variant='contained'
                                    onClick={cancel}
                                    style={{marginRight: 20}} >
                                {t('Cancel')}
                            </Button>
                            <Button size="small"
                                    color="primary"
                                    onClick={this.handleSave}
                                    disabled={isLoading}
                                    variant='contained'>
                                {t('Save')}
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>

            <Dialog open={createDialog} onClose={this.toggleDialog} maxWidth="xs" >
                <Create close={this.toggleDialog}
                        refetch={refetch}
                        QUERY={QUERY}
                        Form={FORM} />
            </Dialog>

            <MassiveUploadDialog
                open={massiveDialog}
                handleClose={this.closeMassive}
                tableName={targetName + " Converters"}
                urlAppend={ invoiceId }
            />
        </div>
        )
    }
}

export default withTranslation("associationComponent")(AssociationComponent)