import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import { Divider, FormControl, Grid, InputAdornment, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'
import { setCurrentUser, AddBPMApplicationMessages } from '../../redux/reducers/BPMReducer'
import { useCurrentUser, useAllCurrencies, useAllLanguages } from '../../redux/selectors'
import classes from './Profile.module.css'
import { primaryColor, disabledColor } from '../../utils/constants'
import CustomPrimaryButton from '../../components/CustomPrimaryButton/CustomPrimaryButton'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
    root: {
        '& .MuiFormLabel-root.Mui-focused': {
            color: primaryColor
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor
        }
    }
})

const EDIT_CURRENT_USER = gql`
    mutation EditCurrentUser($currency: String, $firstName: String, $language: String, $lastName: String, $middleName: String) {
        editCurrentUser(currency: $currency, firstName: $firstName, language: $language, lastName: $lastName, ,middleName: $middleName) {
            user {
                id
                firstName
                middleName
                lastName
                currency {
                    id
                    code
                    name
                    symbol
                }
                language {
                    id
                    codename
                    name
                }
            }
        }
    }
`

const Profile = () => {
    const { t } = useTranslation(['fieldLabels', 'root', 'profile'])
    document.title = `${t('root:Profile')} - BubbleBPM`
    const muiClasses = useStyles()
    const dispatch = useDispatch()

    const currentUser = useCurrentUser();

    const [userData, setUserData] = useState({
        firstName: currentUser.firstName,
        middleName: currentUser.middleName,
        lastName: currentUser.lastName,
        currency: currentUser.currency.code,
        language: currentUser.language.codename,
        prevLang: currentUser.language.codename
    })

    const currencies = useAllCurrencies()
    const languages = useAllLanguages()

    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)

    const [editCurrentUser, {loading: mutationLoading, error: mutationError}] = useMutation(EDIT_CURRENT_USER)

    useEffect(() => {
        if (mutationError) {
            createMessageSnack(t('An error occurred trying to edit user data'), 'error')
        }
    }, [mutationError ])

    const handleSubmit = event => {
        event.preventDefault()
        if (userData.firstName === '') setFirstNameError(true) 
        else setFirstNameError(false)
        if (userData.lastName === '') setLastNameError(true)
        else setLastNameError(false)
        if (userData.firstName === '' || userData.lastName === '') return
        editCurrentUser({
            variables: {
                currency: userData.currency,
                firstName: userData.firstName,
                language: userData.language,
                lastName: userData.lastName,
                middleName: userData.middleName
            }
        })
        .then(result => {
            if (userData.prevLang !== result.data.editCurrentUser.user.language.codename) {
                window.location.reload()
            }

            dispatch(setCurrentUser({
                ...currentUser, 
                firstName: result.data.editCurrentUser.user.firstName,
                middleName: result.data.editCurrentUser.user.middleName,
                lastName: result.data.editCurrentUser.user.lastName,
                currency: result.data.editCurrentUser.user.currency,
                language: result.data.editCurrentUser.user.language,
            }))

            createMessageSnack(t('profile:Profile has been Modified'), 'success')
        })
        .catch(err => {})
    }

    const createMessageSnack = (message, variant) => {
        dispatch(AddBPMApplicationMessages({
            text: message,
            variant: variant,
        }))
    }

    return (
        <>
            <Paper className={classes.Paper}>
                <Typography variant='h5' className={classes.Typography}>{t('root:Profile')}</Typography>
                <Divider className={classes.Divider} />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={0} className={classes.GridContainer}>
                        <Grid item md={4} sm={6} xs={12} className={classes.GridItem}>
                            <TextField 
                                label={t('First Name')}
                                value={userData.firstName}
                                onChange={e => setUserData({...userData, firstName: e.target.value})}
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position='end'>
                                            <CheckCircleOutlineIcon style={{color: userData.firstName !== '' ? primaryColor : disabledColor}} />
                                        </InputAdornment>
                                }}
                                error={firstNameError}
                                helperText={firstNameError ? t('You must complete first name field') : ''}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} className={classes.GridItem}>
                            <TextField 
                                label={t('Middle Name')} 
                                value={userData.middleName}
                                onChange={e => setUserData({...userData, middleName: e.target.value})}    
                                fullWidth 
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} className={classes.GridItem}>
                            <TextField 
                                label={t('Last Name')} 
                                value={userData.lastName}
                                onChange={e => setUserData({...userData, lastName: e.target.value})}
                                fullWidth 
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position='end'>
                                            <CheckCircleOutlineIcon style={{color: userData.lastName !== '' ? primaryColor : disabledColor}} />
                                        </InputAdornment>
                                }}
                                error={lastNameError}
                                helperText={lastNameError ? t('You must complete last name field') : ' '}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} className={classes.GridItem}>
                            <FormControl fullWidth className={muiClasses.root}>
                                <InputLabel id='profile-select-currency-label'>{t('Currency')}</InputLabel>
                                <Select 
                                    labelId='profile-select-currency-label'
                                    value={userData.currency}
                                    onChange={e => setUserData({...userData, currency: e.target.value})}
                                >
                                    {currencies.map(c => {
                                        return <MenuItem key={c.node.id} value={c.node.id}>{`${c.node.code} - ${c.node.name}`}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} className={classes.GridItem}>
                            <FormControl fullWidth className={muiClasses.root}>
                                <InputLabel id='profile-select-language-label'>{t('Language')}</InputLabel>
                                <Select 
                                    labelId='profile-select-language-label'
                                    value={userData.language}
                                    onChange={e => setUserData({...userData, language: e.target.value})}
                                >
                                    {languages.map(l => {
                                        return <MenuItem key={l.node.id} value={l.node.codename}>{`${l.node.codename} - ${l.node.name}`}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.GridButton} style={{textAlign: 'right'}}>
                            <CustomPrimaryButton 
                                type='submit' 
                                label={t('profile:Save')} 
                                disabled={mutationLoading}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    )
}

export default Profile