import React, { useState, useEffect, useRef } from 'react'

import {
    useTableSelectedRows,
    useTableSelectAllState,
    useTableFilters,
    useTableColumns,
} from '../../../redux/selectors'

import BPMTableCell from './BPMTableCell'

import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const CustomBodyTableCell = withStyles(() => ({
    root: {
        padding: "0px 0px 0px 10px",
        '&:last-child': {
            paddingRight: '5px',
        },
    },
}))(TableCell)

const BPMTableRow = (props) => {
    const {
        onRowClickAction,
        data,
        TableObject,
        classes,
        checkboxDisplay,
        preColumnDisplay,
        editButtonDisplay,
        handleClickCheckBox
    } = props

    const [selected, setSelected] = useState(false)
    const [mounted, setMounted] = useState(false)
    const ref = useRef()

    const selectedRows = useTableSelectedRows(TableObject.tableName)
    const allRowsSelected = useTableSelectAllState(TableObject.tableName)
    const filters = useTableFilters(TableObject.tableName)
    const currentTableColumns = useTableColumns(TableObject.tableName)

    useEffect(() => {
        setMounted(true)

        return () => {
            setMounted(false);
        };
    }, []);

    useEffect(() => {
        setSelected(isSelected())
    }, [allRowsSelected, selectedRows, filters, data]);

    const isSelected = () => {
        const rowSelected = selectedRows.find(item => item.id === data.id)
        return allRowsSelected ? rowSelected === undefined : rowSelected !== undefined
    }

    return (
        <TableRow
            style={{ height: 36.5 }}
            hover
            role='checkbox'
            aria-checked={selected}
            tabIndex={-1}
            key={'tableRow_' + data.id}
            selected={selected}
            onClick={() => onRowClickAction(data)}
        >
            {preColumnDisplay ?
                <CustomBodyTableCell padding='checkbox' style={{ width: 50, paddingLeft: '35px', textAlign: 'left' }} key={'checkbox' + data.id}>
                    {checkboxDisplay &&
                        <Checkbox
                            checked={selected}
                            color='primary'
                            style={{ padding: 0 }}
                            ref={ref}
                            onClick={event => {
                                event.stopPropagation()
                                handleClickCheckBox(event, data, ref)
                                setSelected(!selected)
                            }}
                        />
                    }
                </CustomBodyTableCell>
                : null
            }
            {
                TableObject.fieldDescriptions.map(fieldDescription => {
                    const key = "cell" + fieldDescription.id + '_' + data.id;
                    return (
                        <BPMTableCell
                            key={key}
                            data={data}
                            fieldDescription={fieldDescription}
                            classes={classes}
                            tableName={TableObject.tableName}
                        />
                    )
                })
            }
            <CustomBodyTableCell align='center' style={{ width: 30, textAlign: 'center' }}>
                {TableObject.rightButtonDisplay.createWith(editButtonDisplay, data)}
            </CustomBodyTableCell>
        </TableRow>
    )
}

export default BPMTableRow