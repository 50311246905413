import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import { Divider, Grid, InputAdornment, Paper, TextField, Typography } from '@material-ui/core'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'

import { primaryColor, disabledColor } from '../../utils/constants'
import classes from './Password.module.css'
import CustomPrimaryButton from '../../components/CustomPrimaryButton/CustomPrimaryButton'
import { useDispatch } from 'react-redux'
import { setCurrentUser, AddBPMApplicationMessages } from '../../redux/reducers/BPMReducer'
import { useCurrentUser } from '../../redux/selectors'
import { ButtonTooltip } from '../../components/Tooltips/BPMTooltips';
import { validatePassword, passwordValidationMessages, getNewPasswordValidationMessage } from './Utils'

const CHANGE_PASSWORD = gql`
    mutation ChangePassword($password: String, $current: String) {
        changePassword(password: $password, current: $current) {
            user {
                id
                lastPasswordChange
                mustChangePassword
                passwordExpirationDate
            }
        }
    }
`

const Password = () => {
    const { t } = useTranslation()
    const currentUser = useCurrentUser()
    const dispatch = useDispatch()

    document.title = `${t('Change Password')} - BubbleBPM`

    const validationMessages = passwordValidationMessages(t)

    const [password, setPassword] = useState({
        currentPassword: '',
        newPassword: '',
        repeatPassword: ''
    })

    const [changePassword, { loading, error }] = useMutation(CHANGE_PASSWORD)

    useEffect(() => {
        if (error) {
            createMessageSnack(error.message, 'error')
        }
    }, [error])

    const createMessageSnack = (message, variant) => {
        dispatch(AddBPMApplicationMessages({
            text: message,
            variant: variant,
        }))
    }

    const signOut = () => {
        localStorage.clear();
        if (window.location.pathname !== '/') {
            window.location.href = '/'
        } else {
            window.location.reload();
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (!validatePassword(password.newPassword)) {
            createMessageSnack(validationMessages.errorPasswordValidation, 'error');
            return;
        }

        if (password.repeatPassword != password.newPassword) {
            createMessageSnack(validationMessages.strPasswordMatch, 'error');
            return;
        }

        changePassword({
            variables: {
                current: password.currentPassword,
                password: password.newPassword,
            }
        })
            .then(result => {
                createMessageSnack(t('profile:Password has been set successfully'), 'success')
                const user = {
                    ...currentUser, 
                    ...result.data.changePassword.user,
                    lastPasswordChangeRequest: null,
                }
                dispatch(setCurrentUser(user));
            })
            .catch(err => { })
    }

    return (
        <>
            <Paper className={classes.Paper}>
                <Typography variant='h5' className={classes.Typography}>{t('Change Password')}</Typography>
                <Divider className={classes.Divider} />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} className={classes.GridItem}>
                            <TextField
                                label={t('Current Password')}
                                type='password'
                                value={password.currentPassword}
                                onChange={e => setPassword({ ...password, currentPassword: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.GridItem}>
                            <>
                                <TextField
                                    label={t('New Password')}
                                    type='password'
                                    value={password.newPassword}
                                    onChange={e => setPassword({ ...password, newPassword: e.target.value })}
                                    fullWidth
                                    InputProps={{
                                        endAdornment:
                                            <ButtonTooltip title={getNewPasswordValidationMessage(password, validationMessages)} placement={'right'} open={password.repeatPassword.length > 0 || (password.newPassword.length > 0 && !validatePassword(password.newPassword))}>
                                                <InputAdornment position='end'>
                                                    <CheckCircleOutlineIcon style={{ color: validatePassword(password.newPassword) ? primaryColor : disabledColor }} />
                                                </InputAdornment>
                                            </ButtonTooltip>
                                    }}
                                />
                            </>
                        </Grid>
                        <Grid item xs={12} className={classes.GridItem}>
                            <TextField
                                label={t('Repeat Password')}
                                type='password'
                                value={password.repeatPassword}
                                onChange={e => setPassword({ ...password, repeatPassword: e.target.value })}
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position='end'>
                                            <CheckCircleOutlineIcon style={{
                                                color: password.repeatPassword.length > 0 && password.repeatPassword === password.newPassword ?
                                                    primaryColor
                                                    :
                                                    disabledColor
                                            }}
                                            />
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid container spacing={2} className={classes.GridButton}>
                            <Grid item xs={6}>
                                <CustomPrimaryButton
                                style={{ textAlign: 'left' }}
                                type='submit'
                                label={t('profile:Save')}
                                disabled={loading || !validatePassword(password.newPassword) || password.repeatPassword !== password.newPassword}
                                />
                            </Grid>
                            {currentUser.mustChangePassword ?
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <CustomPrimaryButton 
                                style={{ textAlign: 'right' }} 
                                label={t('Logout')}
                                onClick={e => signOut(e)}/>
                            </Grid> 
                            : null}
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    )
}

export default Password