import React, { useState, useEffect } from 'react'

import { Markup } from 'react-render-markup';

import { useHistory } from 'react-router-dom'
import { useCurrentUser } from '../../redux/selectors';

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { gql, useApolloClient } from '@apollo/client'
import { Dialog, DialogTitle, AppBar, ClickAwayListener, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Popper, Toolbar, useMediaQuery, Box } from '@material-ui/core'
import { AccountCircle, Description as DescriptionIcon, Home as HomeIcon, Menu as MenuIcon, Info as InfoIcon } from '@material-ui/icons'

import { setLastPasswordChangeRequest } from '../../redux/reducers/BPMReducer';
import { useDispatch } from 'react-redux'

import classes from './NavBar.module.css'
import { useAuth } from '../../contexts/Auth'
import Check from '../Check/Check'
import SubMenu from './SubMenu/SubMenu'
import AppBarButton from './AppBarButton/AppBarButton'
import CompanyButton from './CompanyButton/CompanyButton'

const setActiveCompanyMutation = gql`
    mutation($id: String!) {
        setActiveCompany(id: $id) {
            user {
                id
            }
        }
    }
`

const logoutUserMutation = gql`
    mutation{
        logoutUser{
            response
        }
    }
`

const NavBar = (props) => {
    const history = useHistory();
    const { t } = useTranslation(['root', 'drawer', 'invoiceProcessing', 'inventoryManagement'])
    const { signout } = useAuth()
    const client = useApolloClient()

    const [showDrawer, setShowDrawer] = useState(false)
    const [companyAnchor, setCompanyAnchor] = useState(null)
    const [profileAnchor, setProfileAnchor] = useState(null)
    const [infoAnchor, setInfoAnchor] = useState(null)
    const [aboutAnchor, setAboutAnchor] = useState(null)
    const smallScreen = useMediaQuery('(max-width:650px)')
    const currentUser = useCurrentUser();

    const dispatch = useDispatch();

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            //console.log(`La ruta ha cambiado a ${location.pathname} mediante ${action}`);
            dispatch(setLastPasswordChangeRequest(null))
        });

        return () => {
            unlisten();
        };
    }, [history]);

    const getCompanyMembership = () => {
        return currentUser.membershipSet ?
            currentUser.membershipSet
                .map(membership => ({id: membership.company.id, name: membership.company.name}))
                .sort((a, b) => a.name.localeCompare(b.name))
            : []
    }

    const handleSignout = () => {
        signout()

        client.mutate({
            mutation: logoutUserMutation
        })
        .catch(err => console.log(err.message))
    }

    const handleChangePassword = () => {
        dispatch(setLastPasswordChangeRequest(new Date().toISOString()))
        setProfileAnchor(null)
    }

    const handleDrawer = () => {
        setShowDrawer(prevState => !prevState)
    }

    const handleChangeCompany = (company_id) => {
        client.mutate({
            mutation: setActiveCompanyMutation,
            variables: {id: company_id}
        })
        .then(
            res => {
                const keys = Object.keys(window.sessionStorage)
                for (let key of keys) {
                    if (key.startsWith('tableFilters')) {
                        window.sessionStorage.removeItem(key)
                    }
                }

                window.location.href = '/';
            }
        )
        .catch(err => console.log(err.message))
    }

    const burgerButton = (<IconButton style={{ color: 'white' }} onClick={handleDrawer}><MenuIcon /></IconButton>)

    return (
        <>
        {
            <AppBar className={ classes.AppBar }>
                <Toolbar className={classes.Toolbar}>
                    {props.disabled ?
                        <img src='//d20xc7q49jirs4.cloudfront.net/static/img/logobubblebpm.svg' alt='bubble_logo' style={{ height: '40px' }} className={classes.Logo} />
                        :
                        <>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {smallScreen ?
                                    burgerButton
                                    :
                                    <Check anyPermission={['access_reports', 'access_inventory', 'access_billing_management', 'access_invoice_processing', 'access_jobs']}>
                                        {burgerButton}
                                    </Check>}

                                <img src='//d20xc7q49jirs4.cloudfront.net/static/img/logobubblebpm.svg' alt='bubble_logo' style={{ height: '40px' }} className={classes.Logo} />

                                <Link className={classes.ButtonLink} to='/'><AppBarButton label={t('Company Home')} /></Link>
                                <Link className={classes.ButtonLink} to='/consumptions'><AppBarButton label={t('Consumptions')} /></Link>
                                <Link className={classes.ButtonLink} to='/myTeam/Employees'><AppBarButton label='My Team' /></Link>
                                <Check anyPermission={['access_reports']}>
                                    <Link className={classes.ButtonLink} to='/fleetMetrics'><AppBarButton label='Dashboard' /></Link>
                                </Check>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <CompanyButton label={currentUser.company.name} onClick={(event) => setCompanyAnchor(companyAnchor ? null : event.currentTarget)} />
                                <IconButton style={{ color: 'white' }} onClick={(event) => setProfileAnchor(profileAnchor ? null : event.currentTarget)}><AccountCircle /></IconButton>
                                <IconButton style={{ color: 'white' }} onClick={(event) => setInfoAnchor(infoAnchor ? null : event.currentTarget)}><InfoIcon /></IconButton>
                            </div>
                        </>
                    }
                </Toolbar>

                <Drawer open={showDrawer} anchor='left' onClose={handleDrawer}>
                    <List className={classes.DrawerContent}>
                        <Link to='/'>
                            <ListItem>
                                <ListItemIcon>
                                    <HomeIcon style={{color: '#fff'}} />
                                </ListItemIcon>
                                <ListItemText>Home</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to='/consumptions' className={classes.HiddenLink}>
                            <ListItem>
                                <ListItemText>{t('Consumptions')}</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to='/myTeam/Employees' className={classes.HiddenLink}>
                            <ListItem>
                                <ListItemText>My Team</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to='/fleetMetrics' className={classes.HiddenLink}>
                            <ListItem>
                                <ListItemText>Dashboard</ListItemText>
                            </ListItem>
                        </Link>
                        <Divider style={{backgroundColor: '#ffffff26'}} />

                        <Check permission='access_billing_management'>
                            <SubMenu
                                label='Bill Management'
                                translation='billManagement'
                                handleDrawer={handleDrawer}
                                items={[
                                    {
                                        link: '/converters/concept',
                                        name: 'Concept Converters'
                                    },
                                    {
                                        link: '/concept',
                                        name: 'Concepts'
                                    },
                                    {
                                        link: '/converters/destination',
                                        name: 'Destination Converters'
                                    },
                                    {
                                        link: '/destination',
                                        name: 'Destinations'
                                    },
                                    {
                                        link: '/chargeGroupConcept',
                                        name: 'Charge Group Concepts'
                                    },
                                    {
                                        link: '/chargeGroup',
                                        name: 'Charge Groups'
                                    },
                                    {
                                        link: '/backoffice/upload_billing',
                                        name: 'Upload Billing'
                                    },
                                    // {
                                    //     link: '/chargeGroupUssage',
                                    //     name: 'Charge Groups Ussage'
                                    // },
                                ]}
                            />
                        </Check>

                        <Check permission='access_invoice_processing'>
                            <SubMenu
                                label='Invoice Processing'
                                translation='invoiceProcessing'
                                handleDrawer={handleDrawer}
                                items={[
                                    {
                                        link: '/webcredentials',
                                        name: 'Web Credentials'
                                    },
                                    {
                                        link: '/invoice',
                                        name: 'Invoice Processing'
                                    },
                                    {
                                        link: '/consolidatedSBO/schedule',
                                        name: 'Consolidated SBO'
                                    },
                                    {
                                        link: '/invoicePayment',
                                        name: 'Invoice Payments'
                                    },
                                    {
                                        link: '/apgl',
                                        name: 'APGL'
                                    },
                                    {
                                        link: '/periods',
                                        name: 'Periods'
                                    },
                                ]}
                            />
                        </Check>

                        <Check permission='access_inventory'>
                            <SubMenu
                                label='Enterprise management'
                                translation='inventoryManagement'
                                handleDrawer={handleDrawer}
                                items={[
                                    {
                                        link: '/account',
                                        name: 'Accounts'
                                    },
                                    {
                                        link: '/legalEntity',
                                        name: 'Legal Entity'
                                    },
                                    {
                                        link: '/user',
                                        name: 'Users'
                                    },
                                    {
                                        link: '/costCenter',
                                        name: 'Cost Centers'
                                    },
                                    {
                                        link: '/devices',
                                        name: 'Devices'
                                    },
                                    {
                                        link: '/glAccount',
                                        name: 'Gl Accounts'
                                    },
                                    {
                                        link: '/sites',
                                        name: 'Sites'
                                    },
                                    {
                                        link: '/inventory',
                                        name: 'Inventory'
                                    },
                                    {
                                        link: '/contract',
                                        name: 'Contracts'
                                    },
                                ]}
                            />
                        </Check>

                        <Check permission='access_jobs'>
                            <SubMenu
                                label='Jobs'
                                translation=''
                                handleDrawer={handleDrawer}
                                items={[
                                    {
                                        link: '/excelExports',
                                        name: 'Excel Exports'
                                    },
                                    {
                                        link: '/dataLoadTemplates',
                                        name: 'Data Load Templates'
                                    },
                                    {
                                        link: '/massiveUploadControl',
                                        name: 'Massive Uploads'
                                    },
                                    {
                                        link: '/processControl',
                                        name: 'Process Control'
                                    },
                                ]}
                            />
                        </Check>

                        <Check permission='access_reports'>
                            <>
                            <Divider style={{backgroundColor: '#ffffff26'}} />
                            <SubMenu
                                label='Reports'
                                translation='reports'
                                icon={<DescriptionIcon style={{color: 'white'}} />}
                                handleDrawer={handleDrawer}
                                items={[
                                    {
                                        link: '/fleetMetrics',
                                        name: 'Fleet Metrics'
                                    },
                                    {
                                        link: '/myTeam/Employees',
                                        name: 'Employees'
                                    },
                                    {
                                        link: '/myTeam/CostCenters',
                                        name: 'Cost Centers'
                                    },
                                    {
                                        link: '/lossreport',
                                        name: 'Loss Devices'
                                    },
                                    {
                                        link: '/optimization',
                                        name: 'Optimization'
                                    },
                                    {
                                        link: '/expensesBySite/ExpensesBySite',
                                        name: 'Expenses by Site'
                                    },
                                    {
                                        link: '/ranking/Ranking',
                                        name: 'Ranking'
                                    },
                                    {
                                        link: '/billingAccounts',
                                        name: 'Billing Accounts'
                                    },
                                    {
                                        link: '/inventoryReport',
                                        name: 'Inventory Report'
                                    },
                                ]}
                            />
                            </>
                        </Check>
                    </List>
                </Drawer>

                <Popper open={Boolean(companyAnchor)} anchorEl={companyAnchor} style={{ zIndex: 1500 }} placement='bottom-end'>
                    <ClickAwayListener onClickAway={() => setCompanyAnchor(null)}>
                        <Paper className={classes.Paper}>
                            {getCompanyMembership().map(company => {
                                return (
                                    <MenuItem
                                        key={company.id}
                                        onClick={() => handleChangeCompany(company.id)}
                                    >
                                        {company.name}
                                    </MenuItem>
                                )
                            })}
                        </Paper>
                    </ClickAwayListener>
                </Popper>

                <Popper open={Boolean(profileAnchor)} anchorEl={profileAnchor} style={{ zIndex: 1500 }} placement='bottom-end'>
                    <ClickAwayListener onClickAway={() => setProfileAnchor(null)}>
                        <Paper className={classes.Paper}>
                            <Link to='/profile' onClick={()=> setProfileAnchor(null)}>
                                <MenuItem>{t('Profile')}</MenuItem>
                            </Link>
                            <MenuItem onClick={handleChangePassword}>{t('Change Password')}</MenuItem>
                            <Divider />
                            <MenuItem onClick={handleSignout}>{t('Log out')}</MenuItem>
                        </Paper>
                    </ClickAwayListener>
                </Popper>

                <Popper open={Boolean(infoAnchor)} anchorEl={infoAnchor} style={{ zIndex: 1500 }} placement='bottom-end'>
                    <ClickAwayListener onClickAway={() => setInfoAnchor(null)}>
                        <Paper className={classes.Paper}>
                            <Link to={{pathname: 'https://docs.bubblebpm.com/isp.pdf'}} target="_blank">
                                <MenuItem>{t('Information security policy')}</MenuItem>
                            </Link>
                            <Link to={{pathname: 'https://docs.bubblebpm.com/ess.pdf'}} target="_blank">
                                <MenuItem>{t('External security statement')}</MenuItem>
                            </Link>
                            <Link to={{pathname: 'https://docs.bubblebpm.com/drp.pdf'}} target="_blank">
                                <MenuItem>{t('Disaster recovery plan')}</MenuItem>
                            </Link>
                            <Link to={{pathname: 'https://docs.bubblebpm.com/sirp.pdf'}} target="_blank">
                                <MenuItem>{t('Security incident response policy')}</MenuItem>
                            </Link>
                            <Divider />
                            { currentUser.company.softwareChangelog || currentUser.company.softwareVersion ?
                                <MenuItem onClick={(event) => { setAboutAnchor(profileAnchor ? null : event.currentTarget); setInfoAnchor(null)}} >{t('About')}</MenuItem>
                                :
                                null
                            }
                        </Paper>
                    </ClickAwayListener>
                </Popper>

                <Dialog onClose={() => setAboutAnchor(null)} open={Boolean(aboutAnchor)} aria-labelledby="simple-dialog-title" fullWidth>
                    <DialogTitle id="simple-dialog-title" sx={{ textAlign:"center" }}>About</DialogTitle>
                    <div style={{padding: 10}}>
                        { currentUser.company.softwareVersion ?
                            <legend>Version: { currentUser.company.softwareVersion }</legend>
                            :
                            null
                        }
                        { currentUser.company.softwareChangelog ?
                            <Box component="fieldset">
                                <legend>Changelog:</legend>
                                <Markup markup={ currentUser.company.softwareChangelog } allowed={["b", "br"]}></Markup>
                            </Box>
                            :
                            null
                        }
                    </div>
               </Dialog>
            </AppBar>
        }
        </>
    )
}

export default NavBar;