/* eslint-disable no-unused-vars */
import React, { Component } from 'react'

import { gql } from '@apollo/client'
import { Dialog, Slide } from '@material-ui/core'

//import Loading from '../../../components/loading'
import Loading from '../../../../components/Loading/Loading'
import HistoryOpener from "./HistoryTable"
import InventoryAssociationsHistoryOpener from './InventoryAssociationsHistory'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class PopupData extends Component {
    state = {
        open : false,
        data : '',
    }
    handleClose = () => {
        this.setState({
          open: false,
        });
    };

  render() {
    const {  client , openD, close, id, TableObject, handleSetFilters, classes, inventoryAssociations} = this.props;
    const Get_data = gql`
    query ($id:String!){
        ${TableObject.unitQuery}
    }
    `;
    return (
        <Dialog
            open={openD}
            maxWidth={false}
            scroll="body"
            fullWidth
            TransitionComponent={Transition}
            disableEnforceFocus
            classes={{paperFullWidth: classes.dialogWidth}}
            onClose={close}
            id={'pickerDialog'+ id }
            >
                <Loading query={Get_data} variables={{ id: id }} fetchPolicy="network-only">
                    {(data, refetch) => {
                        return(
                            <div>
                            <span onClick={close} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '10px', top: '5px'}}>&times;</span>
                            {TableObject.popupContentStrategy({
                                        data:TableObject.popupMapper(data),
                                        refetch:refetch,
                                        close:close,
                                        handleSetFilters:handleSetFilters,
                                        TableObject:TableObject})}
                              { inventoryAssociations ? 
                              <div style={{float:"left"}}>
                                <InventoryAssociationsHistoryOpener data={data}/>
                              </div>
                              : null}
                              <div style={{float:"right"}}>
                                <HistoryOpener TableObject={TableObject} query={TableObject.unitQuery} data={data} id={id}/>
                              </div>
                            </div>
                            )
                        }
                    }
                </Loading>
            </Dialog>
    )
  }
}

export default PopupData
