import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { ConceptsQueries } from './ConceptTableCreator'

const unit_query = `
    allUnits {
        edges {
            node {
                id
                name
            }
        }
    }
`
const technologies_query = `
    allTechnologies {
        edges {
            node {
                id
                name
            }
        }
    }
`

class ConceptFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "ConceptFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Concept', ConceptsQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs( [
            new FormFieldDescription(
                'name',
                new f.InputDescription(new f.TextCreator('name'), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Name'}
            ),
            new FormFieldDescription(
                'technology',
                new f.InputDescription(new f.DropDownCreator('technology'), false),
                generalSection,
                5,
                {label: 'Technology', queryAllDropdownOptions: technologies_query, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'unit',
                new f.InputDescription(new f.DropDownCreator('unit'), false),
                generalSection,
                6,
                {label: 'Unit', queryAllDropdownOptions: unit_query, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'isTax',
                new f.InputDescription(new f.BoolCreator('isTax', false), false),
                generalSection,
                8,
                {label: 'Is Tax'}
            ),
                new FormFieldDescription(
                    'trafficClass',
                new f.InputDescription(new f.OptionsDropDownCreator('trafficClass', ["Domestic", "Roaming"], true), false),
                generalSection,
                9,
                {label: 'Traffic Class'}
            ),
        ])
        return formCreator;
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(ConceptFormCreator)

export default ConceptFormCreator;