/* eslint-disable eqeqeq */
import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    NullClickStrategy, 
    NumberComponentCreator, 
    OnlyFilterActionDisplayStrategy, 
    TableFieldDescription, 
    ThousandSeparatorCreator
} from '../../components/BPMTable/TableMapperCreator'
import {ExportFooterItemCreator} from "../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query Consumptions(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $period:String!,
        $serviceNumber:String,
        $unit:String,
        $group:String,
        $subGroup:String,
        $concept:String,
        $employee:String,
        $firstName:String,
        $lastName:String,
        $enterpriseId:String,
        $currency:String,
        $serviceType:String,
        $provider:String,
        $country:String,
    ) {
        consumptions(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            period:$period,
            serviceNumber:$serviceNumber,
            unit:$unit,
            group:$group,
            subGroup:$subGroup,
            concept:$concept,
            employee:$employee,
            firstName:$firstName,
            lastName:$lastName,
            enterpriseId:$enterpriseId,
            serviceType:$serviceType,
            provider:$provider,
            country:$country,
        ) {
            edges {
                node {
                    inventory {
                        serviceNumber
                        serviceType {
                        	name
                      	}
                    }
                    user {
                        firstName
                        lastName
                        email
                        enterpriseId
                    }
                    unit {
                        name
                    }
                    concept {
                        name
                    }
                    chargeGroup {
                        name
                    }
                    subChargeGroup {
                        name
                    }
                    account {
                        provider {
                          name
                          country {
                            name
                          }
                        }
                    }
                    quantity
                    convertedCost(currency:$currency)
                }
            }
            totalCost(currency:$currency)
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const queries = {tableQuery};

class ConsumptionsTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ConsumptionsTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'serviceNumber',
                new DataMapper("inventory.serviceNumber"),
                new f.FilterDescription(new f.TextFilter("serviceNumber")),
                {label: 'Service Number'}
            ),
            new TableFieldDescription(
                'employee',
                new DataMapper("user.email"),
                new f.FilterDescription(new f.TextFilter("employee")),
                {label: 'Employee Email'}
            ),
            new TableFieldDescription(
                'firstName',
                new DataMapper("user.firstName"),
                new f.FilterDescription(new f.TextFilter("firstName")),
                {label: 'Employee Name'}
            ),
            new TableFieldDescription(
                'lastName',
                new DataMapper("user.lastName"),
                new f.FilterDescription(new f.TextFilter("lastName")),
                {label: 'Employee Surname'}
            ),
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper("user.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("enterpriseId")),
                {label: 'Employee Code'}
            ),
            new TableFieldDescription(
                'unit',
                new DataMapper("unit.name"),
                new f.FilterDescription(new f.TextFilter("unit")),
                {label: 'Unit'}
            ),
            new TableFieldDescription(
                'group',
                new DataMapper("chargeGroup.name"),
                new f.FilterDescription(new f.TextFilter("group")),
                {label: 'Group'}
            ),
            new TableFieldDescription(
                'subGroup',
                new DataMapper("subChargeGroup.name"),
                new f.FilterDescription(new f.TextFilter("subGroup")),
                {label: 'Sub Group'}
            ), 
            new TableFieldDescription(
                'concept',
                new DataMapper("concept.name"),
                new f.FilterDescription(new f.TextFilter("concept")),
                {label: 'Concept'}
            ),
            new TableFieldDescription(
                'quantity',
                new DataMapper("quantity"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Quantity', componentCreator:new ThousandSeparatorCreator(),excelTransform:new DataTransformer(({quantity})=>Number(quantity))}
        ),
            new TableFieldDescription(
                'cost',
                new DataMapper("convertedCost"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Cost', componentCreator:new NumberComponentCreator(this.parentProps.symbol, 2), excelTransform:new DataTransformer(({convertedCost})=>Number(convertedCost).toFixed(2))}
            ),
            new TableFieldDescription(
                'serviceType',
                new DataMapper("inventory.serviceType.name"),
                new f.FilterDescription(new f.TextFilter("serviceType")),
                {label: 'Service Type'}
            ),
            new TableFieldDescription(
                'provider',
                new DataMapper("account.provider.name"),
                new f.FilterDescription(new f.TextFilter("provider")),
                {label: 'Vendor'}
            ),
            new TableFieldDescription(
                'country',
                new DataMapper("account.provider.country.name"),
                new f.FilterDescription(new f.TextFilter("country")),
                {label: 'Country'}
            ),
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ConsumptionsTableCreator)

export default ConsumptionsTableCreator